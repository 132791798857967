import React, { useEffect, useState } from 'react';
import './Navbar.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import myLogo from '../images/icon.png';
import { InputGroup, FormControl } from 'react-bootstrap';
import { useAuth } from '../Services/Authentication/AuthContext';
import NavProfileActionsMenu from '../Components/NavProfileActionsMenu';
import AppTitle from '../Components/app.title';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../Components/Folders/StyledButton';
import { Login, LoginRounded, Person, VerifiedUser } from '@mui/icons-material';

function NavigationLB() {
  const { user } = useAuth();
  const [query, setQuery] = useState('');
  const history = useNavigate();
  const location = useLocation();
  const [navbarBg, setNavbarBg] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    // Change the background color when the scroll is greater than 50px
    if (scrollY > 50) {
      setNavbarBg(true);
    } else {
      setNavbarBg(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && query.trim()) {
      history(`/search-results?q=${encodeURIComponent(query)}`);
    }
  };

  const navLinks = [
    { path: '/', label: 'Home' },
    // { path: '/about', label: 'About', auth: false },
    { path: '/workspace', label: 'Dashboard', auth: true },
    // { path: '/dashboard', label: 'Dashboard', auth: true },
    { path: '/summarize-doc', label: 'Summarize Document', auth: true },
    { path: '/legal-assistant', label: 'Document Generator', auth: true },
    { path: '/legal-query-assistant', label: 'Legal Assistant' },
  ];

  return (
    <Navbar expand="lg" className={`navbar navbar-expand-lg navbar-light bd-navbar sticky sticky-top ${navbarBg ? 'scrolled' : ''}`}>
      <Container fluid className='container-xxl bd-gutter flex-wrap flex-lg-nowrap'>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={myLogo}
            width="78.0"
            height="54.8"
            className="d-inline-block align-center"
          />{' '}
            <span 
              style={{
                marginLeft: '8px',
                position: 'absolute',
                bottom: '8px', // Adjust the value to fine-tune the positioning
                fontSize: '13px',
                fontWeight: '300',
                color: 'wheat',
              }}
            >
              Early Release
            </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" style={{ height: '100%' }}>
          <Nav className="ms-auto my-0 my-lg-0" style={{ maxHeight: '', paddingRight:'10px' }} navbarScroll>
            {navLinks.map(link => (
              (!link.auth || user) && (
                <Nav.Link
                  key={link.path}
                  href={link.path}
                  className={location.pathname === link.path ? 'active' : ''}
                >
                  {link.label}
                </Nav.Link>
              )
              
            ))}
          </Nav>

          {user && (
            <Nav className="d-flex ml-auto px-2">
              <InputGroup>
                <InputGroup.Text id="search">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </InputGroup.Text>
                <FormControl
                  style={{fontSize:'14px', fontWeight:300}}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyUp={handleKeyPress}
                  maxLength={50}
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search"
                />
              </InputGroup>
            </Nav>
          )}

          {user ? (
            <Nav>
              <NavProfileActionsMenu />
            </Nav>
          ) : (
            <Nav>
              <StyledButton startIcon={<Person/>}  variant="contained" sx={{ background: '#198754' }}  className="button" href='/auth'>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-person" viewBox="0 0 20 20">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                </svg> */}
                Login
              </StyledButton>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationLB;
