import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
  Typography,
  IconButton,
  Collapse,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import { ExpandMore, ExpandLess, PictureAsPdf, InsertDriveFile, Image as ImageIcon, Description, Download } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchService from '../Services/Serach/SearchService';
import StyledButton from './Folders/StyledButton';

// Function to render document type icon
const getIconByType = (type) => {
  switch (type) {
    case 'pdf':
      return <PictureAsPdf style={{ marginRight: '8px', color: '#e57373' }} />;
    case 'docx':
      return <InsertDriveFile style={{ marginRight: '8px', color: '#4caf50' }} />;
    case 'image':
    case 'jpg':
    case 'jpeg':
    case 'png':
      return <ImageIcon style={{ marginRight: '8px', color: '#64b5f6' }} />;
    case 'txt':
      return <Description style={{ marginRight: '8px', color: '#ffb74d' }} />;
    default:
      return <InsertDriveFile style={{ marginRight: '8px' }} />;
  }
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


const SearchResults = () => {
  const query = useQuery().get('q');
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [expanded, setExpanded] = useState({});
  const [sortOption, setSortOption] = useState('');
  const [results, setResults] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const fetchSearchResults = async (query) => {
    try {
      //setLoading(true);
      const response = await SearchService.search(query);
      setResults(response.results);
      //setError(null);
    } catch (err) {
      //setError('Failed to fetch search results. Please try again.');
    } finally {
      //setLoading(false);
    }
  };

  useEffect(() => {
    if (query) {
      fetchSearchResults(query);
    }
  }, [query]);

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = results.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDownloadAll = () => {
    if (selected.length === 0) {
      alert('No documents selected!');
    } else {
      alert(`Downloading documents with IDs: ${selected.join(', ')}`);
      // Trigger actual download logic here
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleLinkClick = (row) => {
    setDialogContent(row);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    // Sort logic here
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < results.length}
                  checked={selected.length === results.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <StyledButton
                    style={{width:'250px', marginRight: '16px' }}
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadAll}
                    disabled={selected.length === 0}
                    startIcon={<Download />}
                  >
                    Download Selected
                  </StyledButton>
                  <FormControl variant="outlined" style={{ minWidth: 150, marginRight: '16px' }}>
                    <InputLabel>Sort by</InputLabel>
                    <Select value={sortOption} onChange={handleSortChange} label="Sort by">
                      <MenuItem value="date">Date</MenuItem>
                      <MenuItem value="size">Size</MenuItem>
                      <MenuItem value="author">Author</MenuItem>
                    </Select>
                  </FormControl>

                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
  {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
      const isItemSelected = isSelected(row._id);
      return (
        <React.Fragment key={row._id}>
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row._id)}>
              <Checkbox checked={isItemSelected} />
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Document Icon based on the actual type */}
                {/* {getIconByType(row._source.document_type)} */}
                {getIconByType('png')}
                <div style={{ flexGrow: 1 }}>
                  {/* Title and Expand Icon */}
                  <Typography variant="body1" component="div">
                    <strong style={{ paddingRight: '10px' }}>{row._source.document_title}</strong>
                    <IconButton
                      onClick={() => handleExpandClick(row._id)}
                      style={{ padding: '10px', width: '10px', height: '10px' }}
                    >
                      {expanded[row._id] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
                    {row._source.content || "No description available"}
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    <Typography variant="body2" color="textSecondary">
                      <a href="#" onClick={() => handleLinkClick(row)}>Enterprise &gt; Standard Content Server Interface</a>
                    </Typography>
                    <Typography variant="body2" style={{ marginLeft: '10px', marginRight: '8px' }}>
                      - {row._source.completion_percentage}% - {row._source.file_size} KB
                    </Typography>
                    <Typography variant="body2">
                      - {row._source.user_id} - {new Date(row._source.upload_date).toLocaleDateString()}
                    </Typography>
                  </div>
                </div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} style={{ padding: 0 }}>
              <Collapse in={expanded[row._id]} timeout="auto" unmountOnExit>
                <div style={{ padding: '16px' }}>
                  <Typography variant="body2">{row._source.content}</Typography>
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    })}
</TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={results.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Document Information</DialogTitle>
        <DialogContent style={{width:'375px'}}>
          {dialogContent && (
            <>
              <Typography variant="body1">
                <strong>Title:</strong> {dialogContent._source.document_title}
              </Typography>
              <Typography variant="body2">
                <strong>Author:</strong> {dialogContent._source.user_id}
              </Typography>
              <Typography variant="body2">
                <strong>Upload Date:</strong> {new Date(dialogContent._source.upload_date).toLocaleDateString()}
              </Typography>
              <Typography variant="body2">
                <strong>File Size:</strong> {dialogContent._source.file_size} KB
              </Typography>
              {/* <Typography variant="body2" style={{ marginTop: '10px' }}>
                {dialogContent._source.content || 'No description available'}
              </Typography> */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
          <Button onClick={() => handleNavigate(`/folder/64`)} color="primary" variant="contained">
            Go to Document Location
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SearchResults;