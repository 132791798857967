// src/components/GenerateButton.js
import React from 'react';
import { Button } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import StyledButton from './StyledButton';
import { useNavigate } from 'react-router-dom';

const GenerateButton = () => {
  const navigate = useNavigate();
  const handleGenerate = () => {
    // Logic to handle document generation
    console.log('Generate document');
    navigate('/legal-assistant')

  };

  return (
    <StyledButton startIcon={<NoteAddIcon/>} variant="contained"  sx={{background:'#4a0f73', }} onClick={handleGenerate} style={{ marginBottom: 16 }}>
      Generate Document
    </StyledButton>
  );
};

export default GenerateButton;
