import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { InfoOutlined, InfoRounded, InfoTwoTone } from '@mui/icons-material';
import InfoPanel from '../InfoPanel';

const FileInformationDialog = ({ open, onClose, file, fileName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
      <Stack alignItems="center" direction="row" gap={2}>
            <InfoOutlined/> 
            <Typography variant="body1">File Information</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{minWidth:'450px'}} dividers>
        <List>
          <ListItem>
            <ListItemText primary="File Name" secondary={fileName} />
          </ListItem>
          <ListItem>
            <ListItemText primary="File Type" secondary={file.documentType} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Created Date" secondary={file.createdAt} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Modified Date" secondary={file.updatedAt} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Owner" secondary={file.uploadedBy} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileInformationDialog;
