// src/UserProfile.js
import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { Password } from '@mui/icons-material';

const AccountSettings = () => {

    const navigate = useNavigate();
    function handleChangePassword() {
        navigate('/change-password')
    }

    return (
        <Container className='user-profile text-light rounded '>
            <Row style={{ display: 'flex' }} className="text-light px-3 py-2 radius-card">
                <h3>Account Settings</h3>
            </Row>
            <Row className="text-light px-3 py-2">
                <Button style={{ maxWidth: '275px' }} variant="outlined" onClick={handleChangePassword}> <Password/> Change Password </Button> 
            </Row>
        </Container>
    );
};

export default AccountSettings;