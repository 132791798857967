import React from 'react';
import './LawyerDashboard.css'; // Import the updated CSS

function LawyerDashboard() {
  // Example data (these would typically come from an API)
  const documentsSummarized = 30;
  const documentsUploaded = 256;
  const storageUsed = '7GB';
  const storageLimit = '10GB';
  const documentsGenerated = 76;
  const chatSessionCredits = 28;
  const creditsLeft = 12;

  // Recent Activity
  const recentActivities = [
    { date: 'Jan 24, 2025', description: '"Contract Agreement.pdf" summarized successfully' },
    { date: 'Jan 23, 2025', description: '"Lease Agreement.doc" uploaded' },
    { date: 'Jan 22, 2025', description: 'John Doe - Business Contract Issue' },
    { date: 'Jan 21, 2025', description: 'Asked about NDA agreement terms' },
    { date: 'Jan 15, 2025', description: '"Contract Agreement.pdf" summarized successfully' },
    { date: 'Jan 14, 2025', description: '"Legal Assets.pdf" summarized' },
  ];

  // Messages from potential or existing (converted) clients
  const messages = [
    {
      from: 'Jane Smith',
      subject: 'Question about lease agreement',
      date: 'Jan 24, 2025, 2:45 PM',
      isUnread: true
    },
    {
      from: 'ACME Corp',
      subject: 'Follow-up on NDA terms',
      date: 'Jan 23, 2025, 11:10 AM',
      isUnread: false
    },
    {
      from: 'John Doe',
      subject: 'Contract Negotiation Discussion',
      date: 'Jan 20, 2025, 4:30 PM',
      isUnread: false
    }
  ];

  // Table data for "Your Submitted Requests"
  const submittedRequests = [
    {
      id: '#REQ-1001',
      topic: 'Contract Review',
      description: 'Need a lawyer to review NDA',
      submissionDate: 'Jan 24, 2025',
      status: 'Completed'
    },
    {
      id: '#REQ-1002',
      topic: 'Lease Agreement',
      description: 'Draft lease agreement for office',
      submissionDate: 'Jan 23, 2025',
      status: 'In Progress'
    },
    {
      id: '#REQ-1003',
      topic: 'Employment Contract',
      description: 'Need legal consultation',
      submissionDate: 'Jan 22, 2025',
      status: 'Pending'
    },
    {
      id: '#REQ-1004',
      topic: 'Business Dispute',
      description: 'Seeking advice for contract dispute',
      submissionDate: 'Jan 20, 2025',
      status: 'In Progress'
    },
  ];

  return (
    <div className="lawyer-dashboard-container">
      <h1 className="dashboard-title">Dashboard</h1>

      {/* Top KPI Boxes */}
      <div className="dashboard-kpis">
        <div className="kpi-box">
          <h2>Documents Summarized</h2>
          <p className="kpi-value">{documentsSummarized}</p>
        </div>
        <div className="kpi-box">
          <h2>Documents Uploaded</h2>
          <p className="kpi-value">{documentsUploaded}</p>
          <div className="kpi-subinfo">{storageUsed}/{storageLimit} Storage Used</div>
        </div>
        <div className="kpi-box">
          <h2>Documents Generated</h2>
          <p className="kpi-value">{documentsGenerated}</p>
        </div>
        <div className="kpi-box">
          <h2>Chat Session Credits</h2>
          <p className="kpi-value">{chatSessionCredits}</p>
          <div className="kpi-subinfo">{creditsLeft} credits left</div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="dashboard-main-content">
        {/* Left: Table of "Your Submitted Requests" */}
        <div className="dashboard-left">
          <h2>Your current client requests</h2>
          <table className="requests-table">
            <thead>
              <tr>
                <th>Request ID</th>
                <th>Topic</th>
                <th>Brief Description</th>
                <th>Submission Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {submittedRequests.map((req) => (
                <tr key={req.id}>
                  <td>{req.id}</td>
                  <td>{req.topic}</td>
                  <td>{req.description}</td>
                  <td>{req.submissionDate}</td>
                  <td>
                    <span className={`status-badge status-${req.status.replace(' ', '-').toLowerCase()}`}>
                      {req.status}
                    </span>
                  </td>
                  <td>
                    <a href="#details">View Details &rarr;</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Right side: Recent Activity + Messages */}
        <div className="dashboard-right">
          {/* Recent Activity */}
          <div className="recent-activity-section">
            <h2>Recent Activity</h2>
            <ul className="activity-list">
              {recentActivities.map((activity, idx) => (
                <li key={idx}>
                  <div className="activity-date">{activity.date}</div>
                  <div className="activity-desc">{activity.description}</div>
                </li>
              ))}
            </ul>
          </div>

          {/* Messages Section */}
          <div className="messages-section">
            <h2>Messages</h2>
            <ul className="messages-list">
              {messages.map((msg, idx) => (
                <li key={idx} className={msg.isUnread ? 'unread-message' : ''}>
                  <div className="message-from"><strong>From:</strong> {msg.from}</div>
                  <div className="message-subject"><strong>Subject:</strong> {msg.subject}</div>
                  <div className="message-date">{msg.date}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LawyerDashboard;
