import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './auth-form.css';
import Login from '../Login/Login';
import Signup from '../Signup/Signup';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';


function AuthForm() {
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true);
  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const signup = searchParams.has('signup');
    if (signup) {
      toggleForm();
    }
}, [location]);

  return (
    <Container fluid>
      <Row className="d-flex justify-content-md-center">
        <Col>
          <div className="auth-form" style={{ maxWidth: '365px' }}>
            {isLogin ? (<Login />) : (<Signup />)}
            <Button type="button" onClick={toggleForm} className="toggle-button">
              {isLogin ? 'Need an account? Sign Up' : 'Have an account? Login'}
            </Button>
            <div style={{ display: 'flex', margin: '5px auto' }} className='inline'>
              <a className="get-started-label" style={{ textDecoration: 'none' }} href='/legal-query-assistant'>Get started for free</a>
              {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
              <Divider sx={{ m: 1, mt: '10px', mb: '0px', borderColor: 'white' }} orientation="vertical" flexItem variant="middle" />
              <a className="get-started-label" style={{ textDecoration: 'none' }} href='/request-password-reset'>Forgot Password?</a>
            </div>
          </div>

        </Col>
      </Row>
    </Container>
  );
}

export default AuthForm;
