// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cta-section {
    padding: 80px 30px;
    background-color: #222;
    color: white;
  }
  
  .cta-title {
    font-size: 3rem;
    font-weight: 600;
  }
  
  .cta-description {
    font-size: 1.2rem;
    margin: 20px 0;
  }
  
  .cta-button {
    padding: 10px 40px;
    font-size: 1.2rem;
    border-radius: 50px;
    background-color: #28a745;
    border: none;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Home.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".cta-section {\n    padding: 80px 30px;\n    background-color: #222;\n    color: white;\n  }\n  \n  .cta-title {\n    font-size: 3rem;\n    font-weight: 600;\n  }\n  \n  .cta-description {\n    font-size: 1.2rem;\n    margin: 20px 0;\n  }\n  \n  .cta-button {\n    padding: 10px 40px;\n    font-size: 1.2rem;\n    border-radius: 50px;\n    background-color: #28a745;\n    border: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
