import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Typography,
  Box,
  Container,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { ArrowBack } from "@mui/icons-material";
import StyledButton from "../../Components/Folders/StyledButton";

const LawyerForm = ({
  lawyerFormData,
  handleLawyerFormChange,
  onNavigateBack,
}) => {
  // ---------------------------
  // States
  // ---------------------------
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // For the refine logic
  const [refineLoading, setRefineLoading] = useState(false);
  const [refineAgainLoading, setRefineAgainLoading] = useState(false);

  // Summary + category from backend
  const [refineResponse, setRefineResponse] = useState("");
  const [problemCategory, setProblemCategory] = useState("");

  // Whether the user has done at least one refine
  const [hasRefined, setHasRefined] = useState(false);

  // For “View Summary”
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);

  // Example session + user info (in real code, pass or fetch these)
  const [userId, setUserId] = useState("");
  const [sessionId, setSessionId] = useState("");

  // Ref for dynamically resizing elements
  const containerRef = useRef(null);

  const showError = (errMessage) => {
    console.error(errMessage);
    setSnackbarMessage(errMessage);
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
  };

  // Modified handleResize function
  const handleResize = () => {
    if (containerRef.current) {
      console.log("Resizing element:", containerRef.current);
      containerRef.current.style.width = "100%";
    } else {
      console.warn("handleResize: containerRef is null, skipping resize.");
    }
  };

  useEffect(() => {
    const resizeListener = () => {
      // Use the same null-check before accessing .style
      if (containerRef.current) {
        containerRef.current.style.width = "100%";
      }
    };

    window.addEventListener("resize", resizeListener);

    // Ensure component is mounted before resizing
    setTimeout(() => {
      if (containerRef.current) {
        resizeListener();
      }
    }, 300); // Small delay ensures the component is rendered

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        // Retrieve token from localStorage (or any secure storage)
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No authentication token found.");

        // Extract user ID from token
        const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT
        const userIdFromToken = payload.sub;
        setUserId(userIdFromToken);

        // Call API to start a session
        const sessionResponse = await fetch(
          "https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/start_session",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ userId: userIdFromToken, chatType: "legal_assistance" }),
          }
        );

        if (!sessionResponse.ok) throw new Error("Failed to start session.");
        const sessionData = await sessionResponse.json();
        setSessionId(sessionData.sessionId);
      } catch (error) {
        console.error("Error fetching user session:", error);
        showError("Session initialization failed.");
      }
    };

    fetchUserInfo();
  }, []);

  // ---------------------------
  // Handlers
  // ---------------------------
  const handleSnackbarClose = () => setSnackbarOpen(false);

  // Helper to show success
  const showSuccess = (msg) => {
    console.log(msg);
    setSnackbarMessage(msg);
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  /**
   * Helper text to append at the end of user descriptions.
   */
  const OLANUR_INSTRUCTION =
    " please act as if you are a virtual assistant named Olanur legal buddy to a lawyer and you are taking input from the user and explain the description and detailed analysis and the next steps and at the end of the summary mention that we will reach out to you soon with a matched lawyer detail. Please Keep the response at the max 300 words At the top of your response mention - Thanks for the information please note - this is NOT a legal advice - we are helping you to connect with a right lawyer";

  /**
   * 1) Initial “Refine My Problem”
   */
  const handleRefineProblem = async () => {
    if (!lawyerFormData.description) {
      setSnackbarMessage("Please describe your problem before refining.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    console.log(
      "Starting initial refine with description:",
      lawyerFormData.description
    );
    setRefineLoading(true);

    try {
      // POST request to refine the problem
      const response = await fetch(
        "https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/send_message",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            sessionId,
            // Append the special instruction text to user's description
            message: lawyerFormData.description + OLANUR_INSTRUCTION,
          }),
        }
      );

      console.log("Refine request status:", response.status);

      if (!response.ok) {
        const text = await response.text();
        console.error("Refine endpoint error text:", text);
        throw new Error(`Refine endpoint failed: ${response.status} - ${text}`);
      }

      // Parse JSON
      const data = await response.json();
      console.log("Refinement response data:", data);

      // The backend uses "response" for the refined text
      const summary = data.response || "(No summary returned from backend)";
      const category = data.category || "Unknown";

      console.log("Refined summary:", summary);
      console.log("Identified category:", category);

      // Update state
      setRefineResponse(summary);
      setProblemCategory(category);
      setHasRefined(true);

      showSuccess("Problem refined successfully.");
    } catch (error) {
      console.error("handleRefineProblem error:", error);
      showError("Unable to refine your problem. Please try again.");
    } finally {
      setRefineLoading(false);
    }
  };

  /**
   * 2) “Refine Again”
   */
  const handleRefineAgain = async () => {
    console.log(
      "User clicked refine again, sending 'refine the problem again' plus instructions to backend..."
    );
    setRefineAgainLoading(true);

    try {
      const response = await fetch(
        "https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/send_message",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            sessionId,
            // We add instructions again here, so the AI has full context
            message: "refine the problem again." + OLANUR_INSTRUCTION,
          }),
        }
      );

      console.log("Refine again request status:", response.status);

      if (!response.ok) {
        const text = await response.text();
        console.error("RefineAgain endpoint error text:", text);
        throw new Error(
          `RefineAgain endpoint failed: ${response.status} - ${text}`
        );
      }

      const data = await response.json();
      console.log("RefineAgain response data:", data);

      // Using the same "response" field
      const summary = data.response || "(No summary from refine again)";
      // Keep existing category if none is returned
      const category = data.category || problemCategory;

      console.log("Refined again summary:", summary);
      console.log("Updated category (if any):", category);

      setRefineResponse(summary);
      setProblemCategory(category);

      showSuccess("Refined again successfully.");
    } catch (error) {
      console.error("handleRefineAgain error:", error);
      showError("Unable to refine again. Please try later.");
    } finally {
      setRefineAgainLoading(false);
    }
  };

  /**
   * 3) Final Submit
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Final submission started with refined summary:", refineResponse);

    try {
      setLoading(true);

      // Make the POST request to the new endpoint
      const finalResponse = await fetch(
        "https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/requestlawyer",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            sessionId,
            firstName: lawyerFormData.firstName,
            lastName: lawyerFormData.lastName,
            email: lawyerFormData.email,
            // Combine original description + refined summary
            description: `User Description:\n${lawyerFormData.description}\n\nProblem summary:\n${refineResponse}`,
            category: problemCategory,
          }),
        }
      );

      console.log("Final submission status:", finalResponse.status);

      if (!finalResponse.ok) {
        const text = await finalResponse.text();
        console.error("Final submission error text:", text);
        throw new Error(`Submission failed: ${finalResponse.status} - ${text}`);
      }

      const responseData = await finalResponse.json();
      console.log("Final submission response data:", responseData);

      if (responseData.status === "success") {
        showSuccess(responseData.message || "Submitted successfully!");
        console.log("Request ID:", responseData.data?.requestId);
        console.log("Timestamp:", responseData.data?.timestamp);

        setTimeout(() => {
          onNavigateBack();
        }, 2000);
      } else {
        const errMsg = responseData.message || "Unknown error from server";
        throw new Error(errMsg);
      }
    } catch (error) {
      console.error("handleSubmit error:", error);
      showError(error.message || "Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // View Summary handlers
  const handleViewSummary = () => setShowSummaryDialog(true);
  const handleCloseSummaryDialog = () => setShowSummaryDialog(false);

  return (
    <Container
      component="div" // Ensure the ref is attached to a DOM element
      ref={containerRef}
      sx={{
        p: 2,
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: "600px",
        margin: "auto",
      }}
    >
      {/* Header */}
      <Box display="flex" flexDirection="row" alignItems="center" sx={{ pb: 2 }}>
        <IconButton
          style={{ width: 40, height: 40 }}
          onClick={onNavigateBack}
          edge="start"
          color="inherit"
          aria-label="back"
        >
          <ArrowBack />
        </IconButton>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          style={{ fontWeight: "bold", padding: "0 10px" }}
        >
          Find me a lawyer
        </Typography>
      </Box>

      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
      >
        <TextField
          label="First Name"
          name="firstName"
          fullWidth
          variant="outlined"
          value={lawyerFormData.firstName}
          onChange={handleLawyerFormChange}
        />
        <TextField
          label="Last Name"
          name="lastName"
          fullWidth
          variant="outlined"
          value={lawyerFormData.lastName}
          onChange={handleLawyerFormChange}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          fullWidth
          variant="outlined"
          value={lawyerFormData.email}
          onChange={handleLawyerFormChange}
        />
        <TextField
          label="Description of Problem"
          name="description"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={lawyerFormData.description}
          onChange={handleLawyerFormChange}
        />

        {/* Show refined summary if available */}
        {refineResponse && (
          <Alert severity="info" style={{ whiteSpace: "pre-line" }}>
            {refineResponse}
          </Alert>
        )}

        {/* Show problem category if available */}
        {problemCategory && (
          <Typography variant="body1">
            Category: <strong>{problemCategory}</strong>
          </Typography>
        )}

        {/* Buttons */}
        <Box display="flex" flexWrap="wrap" justifyContent="flex-start" gap={2}>
          {!hasRefined && (
            <StyledButton
              variant="outlined"
              color="secondary"
              onClick={handleRefineProblem}
              disabled={refineLoading}
            >
              {refineLoading ? "Refining..." : "Refine My Problem"}
            </StyledButton>
          )}

          {hasRefined && (
            <StyledButton
              variant="outlined"
              color="secondary"
              onClick={handleRefineAgain}
              disabled={refineAgainLoading}
            >
              {refineAgainLoading ? "Refining again..." : "Refine Again"}
            </StyledButton>
          )}

          {hasRefined && (
            <StyledButton variant="outlined" onClick={handleViewSummary}>
              View Summary
            </StyledButton>
          )}

          {hasRefined && (
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </StyledButton>
          )}
        </Box>
      </form>

      {/* Snackbars */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Dialog for "View Summary" */}
      <Dialog
        open={showSummaryDialog}
        onClose={handleCloseSummaryDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>AI-Generated Summary</DialogTitle>
        <DialogContent>
          <Typography>
            {refineResponse
              ? refineResponse
              : "No summary found. Please refine first."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseSummaryDialog}>Close</StyledButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LawyerForm;
