import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Button, Card, CardActions, CardContent, CardHeader, MenuItem, Select, Typography } from '@mui/material';
import { Container, Form } from 'react-bootstrap';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ExtensionIcon from '@mui/icons-material/Extension';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RateReviewIcon from '@mui/icons-material/RateReview';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  TextField, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import StyledButton from '../../Components/Folders/StyledButton';
import { NavigateBefore, NavigateNext, NavigateNextOutlined, NavigateNextRounded, NavigateNextTwoTone, Navigation, NavigationOutlined, NavigationSharp, NextPlan, NextPlanOutlined, RestartAlt, SkipNext, Start } from '@mui/icons-material';


// Validation Schema
const schema = yup.object().shape({
  service: yup.string().required('Service is required'),
  documentType: yup.string().required('Document type is required'),
  document: yup.string().required('Document is required'),
  details: yup.string().required('Details are required'),
});

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#4a0f73',
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#4a0f73',
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    background: '#4a0f73',
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    background: '#4a0f73'
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));
/*
background: #092756;
  background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top,  rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg,  #670d10 0%, #092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -webkit-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -webkit-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
  background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
  background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg,  #670d10 0%,#092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom,  rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg,  #670d10 0%,#092756 100%);
  

*/
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <ExtensionIcon />,
    3: <DocumentScannerIcon />,
    4: <FormatListNumberedIcon />,
    5: <RateReviewIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['Service Selection', 'Document Type', 'Document Selection', 'Enter Details', 'Review & Submit'];

export default function Wizard1() {

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, watch, formState: { errors } } = methods;


  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <Controller
            name="service"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Service"
                variant="outlined"
                fullWidth
                error={!!errors.service}
                helperText={errors.service?.message}
              />
            )}
          />
        );
      case 1:
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">Document Type</FormLabel>
            <Controller
              name="documentType"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="Contracts and Agreements" control={<Radio />} label="Contracts and Agreements" />
                  <FormControlLabel value="Corporate Policy Documents" control={<Radio />} label="Corporate Policy Documents" />
                  <FormControlLabel value="Dispute Resolution documents" control={<Radio />} label="Dispute Resolution documents" />
                  <FormControlLabel value="Intellectual property and rights documents" control={<Radio />} label="Intellectual property and rights documents" />
                </RadioGroup>
              )}
            />
            <Typography color="error">{errors.documentType?.message}</Typography>
          </FormControl>
        );
      case 2:
        return (
          <Controller
            name="document"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Document"
                variant="outlined"
                fullWidth
                Select=""
                SelectProps={{ native: true }}
                error={!!errors.document}
                helperText={errors.document?.message}
              >
                <MenuItem value="">Select a document</MenuItem>
                <MenuItem value="Document 1">Document 1</MenuItem>
                <MenuItem value="Document 2">Document 2</MenuItem>
              </Select>
            )}
          />
        );
      case 3:
        return (
          <Controller
            name="details"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Details"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                error={!!errors.details}
                helperText={errors.details?.message}
              />
            )}
          />
        );
      case 4:
        const formData = watch();
        return (
          <div>
            <Typography variant="h6">Review & Submit</Typography>
            <Typography><strong>Service:</strong> {formData.service}</Typography>
            <Typography><strong>Document Type:</strong> {formData.documentType}</Typography>
            <Typography><strong>Document:</strong> {formData.document}</Typography>
            <Typography><strong>Details:</strong> {formData.details}</Typography>
          </div>
        );
      default:
        return null;
    }
  }


  return (

    <Card>
      <CardHeader
        title="Legal Assistant Wizard"
        subheader=""
        sx={{ textAlign: 'center' }}

      />

      <CardContent sx={{
        minHeight: '65vh', 
        overflowY: 'scroll', 
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }}>



        <Box sx={{ width: '100%' }}>

          {/* <Stack sx={{ width: '100%' }} spacing={4}> */}
          {/* <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper> */}
          {/* <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* </Stack> */}

          <Container>
            <Card>





              <React.Fragment>
                <CardContent sx={{
                  height: '40vh', 
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                }}>

                  {activeStep === steps.length ? (
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                  ) : (

                    <Form>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        {renderStep(activeStep)}
                      </Typography>
                    </Form>
                  )}

                </CardContent>



              </React.Fragment>
            </Card>
          </Container>
        </Box>
        <CardActions>
          {activeStep === steps.length ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}> <RestartAlt/> Start Again</Button>
            </Box>
          ) : (


            <Box sx={{ display: 'flex !important', flexDirection: 'row', pt: 2, justifyContent:'space-evenly'}}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, width:'150px' }}
              >
               {<NavigateBefore/>} Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              <Button onClick={handleNext} color="inherit"
                sx={{ mr: 1, width:'150px'}} >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'} {<NavigateNextIcon/>}
              </Button>
            </Box>

          )}

        </CardActions>
      </CardContent>
    </Card>

  );
}
