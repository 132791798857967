import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LEGAL_DOC_MANAGEMENT_API_URL } from '../../api/Endpoints';

function Dashboard() {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        handleFetchStatistics();
    }, []);

    const fetchS3Statistics = async (path) => {
        try {
            const response = await axios.get(LEGAL_DOC_MANAGEMENT_API_URL+'/documents/documents/statistics', {
                params: {
                    path: path,
                },
                headers: { 'Content-Type': 'application/json' }
            });
            return response.data;
        } catch (error) {
            console.error('Failed to fetch S3 statistics:', error.response || error);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    };

    const handleFetchStatistics = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await fetchS3Statistics('testlegal2updated3');  // Replace with actual bucket and path
            setData(result);
        } catch (err) {
            setError(err.message || 'Error fetching data');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Typography variant="h6">Loading...</Typography>;
    if (error) return <Typography color="error" variant="h6">{error}</Typography>;

    return (
        <Box display="flex" flexDirection="column" className="dashboard">
            <p>This section will be replaced with statastics!</p>
            <Container maxWidth="md">
            <Typography variant="h5" gutterBottom>
                Statistics for testlegal2updated3
            </Typography>
            {/* <button onClick={handleFetchStatistics}>Fetch Statistics</button> */}
            {loading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {data && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell align="right">Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Total Folders</TableCell>
                                <TableCell align="right">{data.folderCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Total Documents</TableCell>
                                <TableCell align="right">{data.totalDocuments}</TableCell>
                            </TableRow>
                            {data.fileTypesCount && Object.entries(data.fileTypesCount).map(([type, count]) => (
                                <TableRow key={type}>
                                    <TableCell>{type}</TableCell>
                                    <TableCell align="right">{count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
        </Box>
    );
}

export default Dashboard;
