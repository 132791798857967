import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../Services/Authentication/AuthContext";

function PrivateRoute() {
  const { auth, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return auth ? <Outlet /> : <Navigate to="/auth" />;
}

export default PrivateRoute;
