import { Button } from '@mui/material';
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)(({ theme, color = 'primary' }) => ({
    borderRadius: '20px',
    height: '40px',     
    ':hover': {
        color: theme.palette[color].main,
        backgroundColor: 'white',
    },
}));

export default StyledButton;