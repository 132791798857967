import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Card, CardContent, Box, CircularProgress, Snackbar, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Alert, Divider, Toolbar, Tabs, Tab, List, ListItem, ListItemText, Collapse } from '@mui/material';
import { ArrowBack, CloudUpload as CloudUploadIcon, Info, InfoRounded, Send, Close as CloseIcon, DocumentScanner } from '@mui/icons-material';
import { display } from '@mui/system';
import { InfoCircle, SendArrowUp, SendArrowUpFill } from 'react-bootstrap-icons';
import DocAIService from '../Services/DocAI/DocAIService';
import { useLocation, useNavigate } from 'react-router-dom';
import FolderService from '../Services/DocAI/FolderService';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import mammoth from 'mammoth';
import { Folder as FolderIcon, InsertDriveFile as FileIcon } from '@mui/icons-material';

function SummarizeDoc() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('warning');
    const [selectedFileForSummarize, setSelectedFileForSummarize] = useState(null);
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showChatBox, setShowChatBox] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [folders, setFolders] = useState([]);
    const [expandedFolderId, setExpandedFolderId] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = async (file) => {
        try {

            await previewDoc(file.id);
            setShowUploadDialog(false);
            setShowChatBox(true);

        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    const [tabValue, setTabValue] = useState(0);

    const chatEndRef = useRef(null);

    const [docxHtml, setDocxHtml] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const [openInformationDialog, setOpenInformationDialog] = useState(false);


    const handleInformationDialogOpen = () => {
        setOpenInformationDialog(true);
    };

    const handleInformationDialogClose = () => {
        setOpenInformationDialog(false);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const fileParam = searchParams.get('file');
        const urlParam = searchParams.get('url');
        const modeParam = searchParams.get('mode');
        setLoading(true)
        if (modeParam === '1') {
            setShowChatBox(true);
        }

        if (urlParam) {
          setDocuments([{ uri: urlParam, fileType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }]);
          setShowUploadDialog(false);
          setShowChatBox(false);

          fetch(urlParam)
                .then((response) => response.blob())
                .then((blob) => {
                    return blob.arrayBuffer();
                })
                .then((arrayBuffer) => mammoth.convertToHtml({ arrayBuffer }))
                .then((result) => {
                    setDocxHtml(result.value);
                })
                .catch((error) => {
                    console.error("Error rendering DOCX:", error);
                });

        } else if(fileParam) {
            previewDoc(fileParam, modeParam);
            setShowUploadDialog(false);
            setShowChatBox(modeParam === '1' ? true : false);
        }else {
            setShowUploadDialog(true);
            setShowChatBox(true);
        }

        setLoading(false)
    }, [location]);

    const previewDoc = async(fileParam) => {
        try {

            const response = await FolderService.previewDoc(fileParam);
            const isJsonResponse = response.headers['content-type']?.includes('application/json');
            if (isJsonResponse) {
                const textData = await response.data.text();
                const jsonResponse = JSON.parse(textData);

                if (jsonResponse.preSignedUrl) {
                    setDocuments([{ uri: jsonResponse.preSignedUrl, fileType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }]);
                } else {
                    console.log("Unknown document type");
                    throw new Error('Unknown document type');
                }
            } else {
                const contentType = response.headers['content-type'] || 'application/octet-stream';
                const documentBlob = new Blob([response.data], { type: contentType });
                const documentUrl = URL.createObjectURL(documentBlob);
                setDocuments([{ uri: documentUrl, fileType: contentType }]);    
            }
        } catch(error) {
            console.error('Error fetching the document:', error);
            setSnackbarMessage('Failed to load the document');
            setSeverity('error');
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, [chatMessages]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage('');
        setSeverity('warning');
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type || ''; // MIME type
            const fileExtension = file.name.split('.').pop().toLowerCase(); // Get file extension

            const supportedFileTypes = {
                pdf: 'application/pdf',
                jpg: 'image/jpeg',
                jpeg: 'image/jpeg',
                png: 'image/png',
                doc: 'application/msword',
                docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                txt: 'text/plain',
                xls: 'application/vnd.ms-excel',
                xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            };

            // Check if file type or extension is supported
            if (!supportedFileTypes[fileExtension]) {
                setSnackbarMessage('Unsupported file format');
                setSeverity('error');
                setSnackbarOpen(true);
                return;
            }

            // - 

            try {

                if (fileExtension === 'doc' || fileExtension === 'docx') {
                    const formData = new FormData();
                    formData.append("type", 'docx');
                    formData.append('file', file);
                    
                    setLoading(true);
                    const preSignedResponse = await FolderService.uploadFile(formData, -1, true)
                    setLoading(false);
                    if (preSignedResponse.preSignedUrl) {
                        setDocuments([{ uri: preSignedResponse.preSignedUrl, fileType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }]);
                    } else {
                        setSnackbarMessage('Failed to load the document');
                        setSeverity('error');
                        setSnackbarOpen(true);
                    }
                    // Make Axios call to get the temporary S3 URL
                    // const response = await axios.post('/api/s3/generate-url', {
                    //     fileName: file.name,
                    //     fileType: supportedFileTypes[fileExtension],
                    // });
    
                    // const s3Url = response.data.url;
    
                    // setDocuments([{ uri: s3Url, fileType: supportedFileTypes[fileExtension] }]);
                } else {
                    const fileUrl = URL.createObjectURL(file);
                    setDocuments([{ uri: fileUrl, fileType: supportedFileTypes[fileExtension] }]);
                }
                //const fileUrl = URL.createObjectURL(file);
                //setDocuments([{ uri: fileUrl, fileType: supportedFileTypes[fileExtension] }]);
                setSelectedFileForSummarize(file);
                setShowUploadDialog(false); // Close dialog after selecting file
            } catch (error) {
                console.error('Failed to create file URL', error);
                setSnackbarMessage('Failed to load the document');
                setSeverity('error');
                setSnackbarOpen(true);
                setLoading(false);
            }
        }
    };

    const handleSummarize = async () => {
        if (!selectedFileForSummarize) {
            setSnackbarMessage('No file selected');
            setSnackbarOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFileForSummarize);
        setLoading(true);
        try {
            // await new Promise(resolve => setTimeout(resolve, 2000)); // Simulating a delay
            // setLoading(false);

            return await DocAIService.summarizeDocument(userInput, formData);
        } catch (error) {
            console.error('Failed to summarize document', error);
            setSnackbarMessage('Failed to summarize document');
            setSnackbarOpen(true);
            setLoading(false);
            throw error.response ? error.response.data : new Error('Network Error');
        }
    };

    const handleWordDocument = async () => {
        
    }

    const handleOpenUploadDialog = () => {
        {showChatBox ? setShowUploadDialog(true) : navigate('/legal-assistant')}
    };

    const handleCloseUploadDialog = () => {
        setShowUploadDialog(false);
        setExpandedFolderId(null);
    };

    const handleSendMessage = async (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            await handleSendButtonClick();
        }
    };

    const handleSendButtonClick = async () => {

        try {
            if (!userInput.trim()) {
                setSnackbarMessage('Please enter a message');
                setSnackbarOpen(true);
                return;
            }

            if (!selectedFileForSummarize) {
                setSnackbarMessage('No file selected');
                setSnackbarOpen(true);
                return;
            }

            // Add user message to chat
            setChatMessages(prevMessages => [
                ...prevMessages,
                { text: userInput, type: 'user' }
            ]);
            setUserInput('');

            // Show animated dots during loading
            setChatMessages((prevMessages) => [
                ...prevMessages,
                { text: '....', type: 'loading' } // Placeholder message for loading
            ]);


            setLoading(true);
            const [response] = await Promise.all([
                handleSummarize(),
                new Promise((resolve) => setTimeout(resolve, 5000)) // Minimum 5 seconds delay
            ]);
            setLoading(false);

            // Remove the loading dots message and replace with the actual response
            setChatMessages((prevMessages) =>
                prevMessages.filter((message) => message.type !== 'loading')
            );

            // Simulate server response
            setChatMessages(prevMessages => [
                ...prevMessages,
                { text: `${response.openai_response}`, type: 'server' }
            ]);
        } catch (error) {
            // Simulate server response
            setChatMessages(prevMessages => [
                ...prevMessages,
                { text: `Unable to fetch the results please try again after sometime.`, type: 'server' }
            ]);
            console.log('Failed to summarize the document', error);
        } finally {
            setLoading(false);
        }
    };

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
        setExpandedFolderId(null);
    };
    
    const fetchFolders = async () => {
        try {
            const response = await FolderService.GetFoldersByUser();
            setFolders(response);
        } catch (error) {
            console.error('Error fetching folders:', error);
        }
    };

    const fetchFiles = async (folderId) => {
        try {
            const response = await FolderService.getFilesByFolderId(folderId);
            setFolders(folders.map(folder => (
                folder.id === folderId ? { ...folder, files: response } : folder
            )));
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const handleFolderClick = (folderId) => {
        setExpandedFolderId(expandedFolderId === folderId ? null : folderId);
        if (expandedFolderId !== folderId) {
            fetchFiles(folderId);
        }
    };

    const handleSelectButtonDialog = async (event) => {
        event.preventDefault();
        const fileToSummarize = tabValue === 0 ? selectedFileForSummarize : selectedFile;
        if (!fileToSummarize) {
            setSnackbarMessage('No file selected');
            setSnackbarOpen(true);
            return;
        }

        if (tabValue === 1) {
            try {
                await previewDoc(fileToSummarize.id);
                setShowChatBox(true);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        }
        handleCloseUploadDialog();
    };
    

    useEffect(() => {
        if (showUploadDialog && tabValue === 1) {
            fetchFolders();
        }
    }, [showUploadDialog, tabValue]);

    return (
        <div className="summarize-doc" style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} md={7} style={{ display: 'flex', height: '100%', flexDirection: 'column' }}> */}
                <Grid item xs={12} md={!showChatBox ? 12 : 7} style={{ display: 'flex', flexDirection: 'column' }}>
                <Card>
                    <CardContent style={{ display: 'flex', flexDirection: 'column' }}>

                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center" // Align items vertically centered
                            justifyContent="space-between" // Space between left and right content
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center" // Align items vertically centered
                            >
                                <IconButton style={{ margin: '0 0 0px 0px', width: '40px', height: '40px' }} onClick={() => navigate(-1)} edge="start" color="inherit" aria-label="back">
                                    <ArrowBack />
                                </IconButton>
                                <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center', padding: '0px 10px 0px 10px' }}>
                                    Document Preview
                                    <Info style={{ marginLeft: '5px' }} />
                                </Typography>
                            </Box>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenUploadDialog}
                                startIcon={showChatBox ? <CloudUploadIcon /> : <DocumentScanner />}
                                style={{
                                    maxWidth: '45%',
                                    borderRadius: '20px',
                                    padding: '10px 20px',
                                    background: 'linear-gradient(to right, #6a11cb, #2575fc)',
                                    color: '#ffffff',
                                    textTransform: 'none',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginBottom: '5px'
                                }}
                            >
                                {showChatBox ? 'Upload Another Document' : 'Generate Another Document'}
                            </Button>
                        </Box>

                        <Box style={{ flex: 1 }}>
                            <Box
                                style={{
                                    height: '75vh', // Limit the height to the viewport height
                                    border: '1px solid #ddd',
                                    borderRadius: '4px',
                                    padding: '2px',
                                     // Enable scrollbars when content exceeds the height
                                }}
                            >
                                {documents.length > 0 ? (
                                    documents[0].fileType === 'image/jpeg' || documents[0].fileType === 'image/png' ? (
                                        <Box
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                overflow: 'auto', // Enable scrolling when the image exceeds the container size
                                            }}
                                        >
                                            <img
                                                src={documents[0].uri}
                                                alt="Preview"
                                                style={{
                                                    width: '100%', // Ensure it takes up full width
                                                    maxWidth: 'unset', // Allow image to grow beyond container
                                                    maxHeight: 'unset', // Allow image to grow beyond container
                                                    objectFit: 'contain', // Or 'none' depending on your desired effect
                                                }}
                                            />
                                        </Box>
                                    ) : documents[0].fileType === 'application/pdf' ? (
                                        <iframe
                                            src={documents[0].uri}
                                            style={{ width: '100%', height: '100%' }} // Adjust iframe to fill the container
                                            frameBorder="0"
                                            title="PDF Preview"
                                        />
                                    ) : documents[0].fileType === 'text/plain' ? (
                                        <iframe
                                            src={documents[0].uri}
                                            style={{ width: '100%', height: '100%' }} // Adjust iframe to fill the container
                                            frameBorder="0"
                                            title="Text Preview"
                                        />
                                    ) : documents[0].fileType === 'application/msword' ||
                                    documents[0].fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                        <iframe
                                            src={`https://view.officeapps.live.com/op/embed.aspx?src=` + encodeURIComponent(documents[0].uri)}
                                            frameBorder="0"
                                            width="100%"
                                            height="100%"
                                            title="Word Document Preview"
                                        />
                                    ) : null
                                ) : (
                                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                        <Typography>No document selected</Typography>
                                    </Box>
                                )}



                                {loading && (
                                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                        <CircularProgress />
                                    </Box>
                                )}


                            </Box>
                        </Box>
                    </CardContent>
                </Card>

                </Grid>
                {showChatBox && (
                <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card style={{ height: '100%' }}>
                        <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                            {/* <Typography variant="h6">Chat Window</Typography> */}
                            <Box
                                style={{
                                    flex: 1,
                                    overflowY: 'auto',
                                    border: '1px solid #ddd',
                                    borderRadius: '4px',
                                    padding: '10px',
                                    marginTop: '10px',
                                    maxHeight: 'calc(85vh - 100px)' // Adjust based on header/footer height
                                }}
                            >
                                <Box display="flex" flexDirection="column">
                                    {chatMessages.map((msg, index) => (
                                        <>
                                            <Box
                                                key={index}
                                                display="flex"
                                                flexDirection={msg.type === 'user' ? 'row-reverse' : 'row'}
                                                mb={1}
                                                alignItems="flex-end"
                                            >
                                                <Box ref={chatEndRef}
                                                    p={1}
                                                    borderRadius="16px"
                                                    bgcolor={msg.type === 'user' ? '#dcf8c6' : '#f1f0f0'}
                                                    maxWidth="75%"
                                                    textAlign={msg.type === 'user' ? 'right' : 'left'}
                                                >
                                                    {msg.text}
                                                    <div />
                                                </Box>
                                            </Box>

                                        </>
                                    ))}

                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" sx={{ paddingTop: '10px' }} borderTop="1px solid #ddd">
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={1}
                                    value={userInput}
                                    onChange={(e) => setUserInput(e.target.value)}
                                    placeholder="Ask a question about this document..."
                                    onKeyDown={handleSendMessage}
                                    style={{ marginRight: '10px' }} // Add space between input and button
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSendButtonClick}
                                    disabled={loading}
                                    startIcon={<SendArrowUpFill />}
                                    style={{
                                        borderRadius: '20px',
                                        padding: '10px 20px',
                                        background: 'linear-gradient(to right, #6a11cb, #2575fc)',
                                        color: '#ffffff',
                                        textTransform: 'none',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                        maxWidth: '25%'
                                    }}
                                >
                                    Send
                                    {loading && <CircularProgress style={{ color: 'white' }} size={20} />}
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>)}

                {/* {docxHtml && ( */}
                {/* <div
                    style={{
                        padding: '10px',
                        border: '1px solid #ddd',
                        height: '100%',
                        overflowY: 'auto',
                    }}
                    dangerouslySetInnerHTML={{ __html: docxHtml }}
                /> */}
            {/* )} */}
            </Grid>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={severity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        <Dialog
            open={showUploadDialog}
            onClose={handleCloseUploadDialog}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                style: { borderRadius: 15, padding: '5px' }
            }}
        >
            {/* <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem' }}> */}
                {/* {tabValue === 0 ? 'Upload Document' : 'Fetch Document from Cloud'} */}

                
            {/* </DialogTitle> */}
            <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                    handleTabChange(newValue);
                    setExpandedFolderId(null); // Reset expanded folder on tab switch
                }}
                centered
                variant="fullWidth"
                indicatorColor="primary"
            >
                <Tab 
                    label="Upload Document"
                    disableRipple
                    sx={{
                        "&:hover": {
                            borderBottom: "2px solid",
                            borderColor: "primary.main",
                            backgroundColor: "transparent" // Prevents background color change
                        }
                    }}
                />
                <Tab 
                    label="Fetch Document from Cloud"
                    disableRipple
                    sx={{
                        "&:hover": {
                            borderBottom: "2px solid",
                            borderColor: "primary.main",
                            backgroundColor: "transparent" // Prevents background color change
                        }
                    }}
                />
            </Tabs>

            <Divider />

            {/* Tab Navigation */}
            
{/* 
            <Divider /> */}

            <DialogContent>
                {tabValue === 0 ? (
                    <>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mt={3}
                            mb={3}
                            style={{
                                border: '2px dashed #aaa',
                                borderRadius: '12px',
                                width: '150px',
                                height: '150px',
                                margin: '0 auto',
                                padding: '20px',
                            }}
                        >
                            <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="file-upload">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <CloudUploadIcon style={{ fontSize: '4rem', color: '#1976d2' }} />
                                </IconButton>
                            </label>
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            Please select a document to upload. Supported file formats are:
                        </Typography>
                        <ul>
                            <li>PDF</li>
                            <li>Images (JPG, JPEG, PNG)</li>
                            <li>Text Documents (TXT, DOCX, DOC)</li>
                        </ul>
                    </>
                ) : (
                    <List>
                        {folders.map((folder) => (
                            <React.Fragment key={folder.id}>
                                <ListItem button onClick={() => handleFolderClick(folder.id)}>
                                    <FolderIcon style={{ marginRight: '10px' }} />
                                    <ListItemText primary={folder.name} />
                                </ListItem>
                                <Collapse in={expandedFolderId === folder.id} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {folder.files && folder.files.length > 0 ? (
                                            folder.files.map((file) => (
                                                <ListItem
                                                    key={file.id}
                                                    style={{
                                                        paddingLeft: '30px',
                                                        backgroundColor: selectedFile && selectedFile.id === file.id ? '#e0f7fa' : 'transparent', // Highlight selected file
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => setSelectedFile(file)}
                                                >
                                                    <FileIcon style={{ marginRight: '10px' }} />
                                                    <ListItemText primary={file.documentUrl.split('/').pop()} />
                                                </ListItem>
                                            ))
                                        ) : (
                                            <ListItem style={{ paddingLeft: '30px' }}>
                                                <ListItemText primary="No files present under this folder" style={{ fontStyle: 'italic', color: '#888' }} />
                                            </ListItem>
                                        )}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseUploadDialog} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={(event) => handleSelectButtonDialog(event)}
                    color="primary"
                    variant="contained"
                    style={{
                        background: 'linear-gradient(to right, #6a11cb, #2575fc)',
                        color: '#ffffff',
                        borderRadius: '20px',
                        padding: '10px 20px'
                    }}
                >
                    {tabValue === 0 ? 'Upload' : 'Select'}
                </Button>
            </DialogActions>
        </Dialog>

            {/* <Dialog
                open={showUploadDialog}
                onClose={handleCloseUploadDialog}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    style: { borderRadius: 15, padding: '5px' }  // Round the dialog box corners
                }}
            >
                <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem' }}>
                    Upload Document
                </DialogTitle>

                <Divider />

                <DialogContent>


                    
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={3}
                        mb={3}
                        style={{
                            border: '2px dashed #aaa',
                            borderRadius: '12px',
                            width: '150px',
                            height: '150px',
                            margin: '0 auto',
                            padding: '20px',
                        }}
                    >
                        <input
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="file-upload">
                            <IconButton
                                color="primary"
                                component="span"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <CloudUploadIcon style={{ fontSize: '4rem', color: '#1976d2' }} />
                            </IconButton>
                        </label>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                        Please select a document to upload. Supported file formats are:
                    </Typography>

                    <ul>
                        <li>PDF</li>
                        <li>Images (JPG, JPEG, PNG)</li>
                        <li>Text Documents (TXT, DOCX, DOC)</li>
                    </ul>
                    <Divider />

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseUploadDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (!selectedFileForSummarize) {
                                setSnackbarMessage('No file selected');
                                setSnackbarOpen(true);
                                return;
                            }
                            handleCloseUploadDialog();
                        }}
                        color="primary"
                        variant="contained"
                        style={{
                            background: 'linear-gradient(to right, #6a11cb, #2575fc)',
                            color: '#ffffff',
                            borderRadius: '20px',
                            padding: '10px 20px'
                        }}
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog open={openInformationDialog} onClose={handleInformationDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Document Information
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleInformationDialogClose}
                        aria-label="close"
                        style={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box>
                        <Typography variant="subtitle1"><strong>Title:</strong> Title'</Typography>
                        <Typography variant="subtitle1"><strong>Owner:</strong> Owner'</Typography>
                        <Typography variant="subtitle1"><strong>Upload Date:</strong> Date</Typography>
                        <Typography variant="subtitle1"><strong>Size:</strong> Size</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUploadDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SummarizeDoc;
