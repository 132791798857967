import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText,
    Snackbar, Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderService from '../../Services/DocAI/FolderService';
import { useNavigate } from 'react-router-dom';

const MAX_FILE_SIZE_MB = 20;
const MAX_FILES_ALLOWED = 5;

const UploadDocumentDialog = ({ open, onClose, onUpload, folderId }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const navigate = useNavigate();

    const handleFileSelection = (event) => {
        const files = Array.from(event.target.files);

        if (files.length > MAX_FILES_ALLOWED) {
            setSnackbar({
                open: true,
                message: `You can only select up to ${MAX_FILES_ALLOWED} files.`,
                severity: 'warning'
            });
            return;
        }

        for (let file of files) {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                setSnackbar({
                    open: true,
                    message: `Each file must be less than ${MAX_FILE_SIZE_MB} MB.`,
                    severity: 'warning'
                });
                return;
            }
        }

        setSelectedFiles(files);
    };

    const handleDeleteFile = (fileName) => {
        setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
    };

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            setSnackbar({ open: true, message: 'Please select at least one file.', severity: 'warning' });
            return;
        }

        setIsUploading(true);

        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('files', file);
        });

        try {
            const response = await FolderService.uploadMultipleFiles(formData, folderId);
            setIsUploading(false);
            setSnackbar({ open: true, message: 'File(s) uploaded successfully.', severity: 'success' });
            onUpload(response.data); // Handle the response from the server
            onClose();
            setSelectedFiles([]);
        } catch (error) {
            setIsUploading(false);
            setSnackbar({ open: true, message: `Upload failed: ${error.response?.data?.message || error.message}`, severity: 'error' });
        } finally {
            setIsUploading(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: '', severity: 'info' });
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Upload Documents</DialogTitle>
                <DialogContent style={{ minWidth: '100px', maxWidth: '750px' }} dividers>
                    <input
                        type="file"
                        multiple
                        onChange={handleFileSelection}
                        disabled={isUploading}
                    />
                    <List>
                        {selectedFiles && selectedFiles.map((file, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={file.name} secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFile(file.name)} disabled={isUploading}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={isUploading}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpload}
                        color="primary"
                        startIcon={isUploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
                        disabled={isUploading}
                    >
                        {isUploading ? 'Uploading...' : 'Upload'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default UploadDocumentDialog;
