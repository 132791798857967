// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Folders {
    background-color: #f3f2f800;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: left;
  }
  
  h1 {
    color: #5a4e7c;
    margin-bottom: 20px;
  }
  
  .Folder {
    cursor: pointer;
    margin: 10px;
    background: transparent; /* Light purple background */
    border-radius: 10px;
    padding: 20px; /* Increased padding */
    width: 150px; /* Increased width */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    transition: transform 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .Folder:hover {
    transform: scale(1.05);
    /* background-color: #64ad99; Slightly darker purple on hover */
  }
  
  .Folder h2 {
    color: #ffffff;
    font-size: 20px; /* Larger font size */
  }
  
  .Folder ul {
    list-style-type: none;
    padding: 0;
    margin-top: 10px; /* Added margin top for spacing */
  }
  
  .Folder li {
    background-color: #e6e2f3;
    margin: 5px 0;
    padding: 8px; /* Slightly more padding for each document */
    border-radius: 5px;
    color: #5a4e7c;
    font-size: 16px; /* Larger font size for documents */
  }
  
  .Folder button {
    width: 25px;
    height: 25px;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Folders/Folders.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,uBAAuB,EAAE,4BAA4B;IACrD,mBAAmB;IACnB,aAAa,EAAE,sBAAsB;IACrC,YAAY,EAAE,oBAAoB;IAClC,8CAA8C;IAC9C,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,sBAAsB;IACtB,+DAA+D;EACjE;;EAEA;IACE,cAAc;IACd,eAAe,EAAE,qBAAqB;EACxC;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,gBAAgB,EAAE,iCAAiC;EACrD;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,YAAY,EAAE,4CAA4C;IAC1D,kBAAkB;IAClB,cAAc;IACd,eAAe,EAAE,mCAAmC;EACtD;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".Folders {\n    background-color: #f3f2f800;\n    padding: 20px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    align-items: left;\n  }\n  \n  h1 {\n    color: #5a4e7c;\n    margin-bottom: 20px;\n  }\n  \n  .Folder {\n    cursor: pointer;\n    margin: 10px;\n    background: transparent; /* Light purple background */\n    border-radius: 10px;\n    padding: 20px; /* Increased padding */\n    width: 150px; /* Increased width */\n    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */\n    transition: transform 0.2s;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  .Folder:hover {\n    transform: scale(1.05);\n    /* background-color: #64ad99; Slightly darker purple on hover */\n  }\n  \n  .Folder h2 {\n    color: #ffffff;\n    font-size: 20px; /* Larger font size */\n  }\n  \n  .Folder ul {\n    list-style-type: none;\n    padding: 0;\n    margin-top: 10px; /* Added margin top for spacing */\n  }\n  \n  .Folder li {\n    background-color: #e6e2f3;\n    margin: 5px 0;\n    padding: 8px; /* Slightly more padding for each document */\n    border-radius: 5px;\n    color: #5a4e7c;\n    font-size: 16px; /* Larger font size for documents */\n  }\n  \n  .Folder button {\n    width: 25px;\n    height: 25px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
