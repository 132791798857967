import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "'Ubuntu', sans-serif",
    h1: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 400,
      fontSize: "2rem",
    },
    h2: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 400,
      fontSize: "1.75rem",
    },
    h3: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 400,
      fontSize: "1.5rem",
    },
    h4: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 400,
      fontSize: "1.25rem",
    },
    h5: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 400,
      fontSize: "1.1rem",
    },
    h6: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "1rem",
    },
    body1: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.875rem",
    },
    body2: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.875rem",
    },
    button: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      textTransform: "none",
      fontSize: "0.9rem",
    },
    caption: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.75rem",
    },
    subtitle1: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.875rem",
    },
    subtitle2: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.875rem",
    },
    overline: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.75rem",
    },
    a: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.875rem",
    },
    p: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.875rem",
    },
    span: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 300,
      fontSize: "0.875rem",
    },
  },
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

export default theme;