import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from '../Authentication/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InfoPanel from '../../Components/InfoPanel';

const ConfirmUserAccount = () => {

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { confirmUserRegistration, error } = useAuth();

    useEffect(() => {
        const token = searchParams.get('token');
        if (!token) {
            setMessage('Invalid or missing confirmation token.');
            setLoading(false);
            return;
        }

        confirmRegistration(token);
    }, []);

    const confirmRegistration = async (token) => {
        try {
            setLoading(true);
            const response = await confirmUserRegistration(token);
            console.log("Reponse: " + response);
            setMessage('Account successfully confirmed. Redirecting to login...');
            setTimeout(() => navigate('/auth'), 3000);
        } catch (err) {
            setMessage(err.message || 'Failed to confirm account. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (

        <Container style={{ display: 'flex' }} className='user-profile text-light rounded '>
            <Row>
                <InfoPanel />
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="text-light px-3 py-2 radius-card">
                <h3>Confirm Account Registration</h3>
            </Row>
            <Row className='p-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading ? (
                        <Alert variant="info">Validating token, please wait...</Alert>
                    ) : (
                        <>
                            {message && <Alert variant="success">{message}</Alert>}
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && (
                                <Col className="text-center">
                                    <Button onClick={() => navigate('/auth')} style={{ maxWidth: '150px' }}>
                                        Go to Login
                                    </Button>
                                </Col>
                            )}
                        </>
                    )}
            </Row>
        </Container>
    );
};

export default ConfirmUserAccount;
