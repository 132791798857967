const folders = [
    {
        id: 1,
        name: "Folder 1",
        documents: [
            { id: 1, name: "Document 1.pdf", created:"2021-02-04",summary:"Data Analysis", size:"1kb" },
            { id: 2, name: "Document 2.docx" }
        ]
    },
    {
        id: 2,
        name: "Folder 2",
        documents: [
            { id: 3, name: "Document 3.pdf" },
            { id: 4, name: "Document 4.docx" }
        ]
    },
    {
        id: 3,
        name: "Folder 3",
        documents: [
            { id: 3, name: "Document 5.pdf" },
            { id: 4, name: "Document 6.docx" }
        ]
    },
    {
        id: 4,
        name: "Folder 4",
        documents: [
            { id: 3, name: "Document 7.pdf" },
            { id: 4, name: "Document 8.docx" }
        ]
    }
];

export default folders;