import axiosInstance from '../../api/axiosInstance';
import { LEGA_SEARCH_DOC_API_URL, LEGAL_ASSIST_AI_NLP_SERVICE_API_URL, LEGAL_DOC_MANAGEMENT_API_URL } from '../../api/Endpoints';

const DOCAI_SERVICE_API = '/documents'
const baseURL = LEGA_SEARCH_DOC_API_URL;
const SearchService = {

  search: async (query) => {
    try {

      var formdata = new FormData();
      formdata.append("query",  query);
      axiosInstance.defaults.headers = {}

        const response = await axiosInstance.post(baseURL + '/legalsearch/', { query: query }, {
              headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*'
              }
          }
      );
      return response.data;
    } catch (error) {
      console.log('Error search : '.error.response);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  }
};

export default SearchService;
