import React, { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { FolderOpen, Info, Delete } from '@mui/icons-material';

const FolderContextMenu = ({ anchorPosition, handleClose, open }) => {
  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition ? { top: anchorPosition.y, left: anchorPosition.x } : undefined}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={() => { handleClose(); alert('Open clicked'); }}>
        <ListItemIcon>
          <FolderOpen />
        </ListItemIcon>
        <ListItemText primary="Open" />
      </MenuItem>
      <MenuItem onClick={() => { handleClose(); alert('Show Details clicked'); }}>
        <ListItemIcon>
          <Info />
        </ListItemIcon>
        <ListItemText primary="Show Details" />
      </MenuItem>
      <MenuItem onClick={() => { handleClose(); alert('Delete clicked'); }}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText primary="Delete" />
      </MenuItem>
    </Menu>
  );
};

export default FolderContextMenu;
