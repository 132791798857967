import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import './Navigation/Workspace/Workspace.css';
import './ProfileSection/UserProfile.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import NavigationLB from './Navbar/NavbarLB';
import Footer from './Footer/Footer';
import AppRoutes from './Routes/Routes';
import { AuthProvider } from './Services/Authentication/AuthContext';


function App() {
  return (


    <div className='App'>
      <AuthProvider>
        <Router>
          <NavigationLB />
          <Container className='container bd-gutter flex-wrap flex-lg-nowrap'>
            <div className='App-body'>
              <Container fluid>
                <AppRoutes />
              </Container>
            </div>
          </Container>
        </Router>
      </AuthProvider>
      <Footer />
    </div>
  );
}

export default App;

