import { Navigate, Route, Routes } from "react-router-dom";
import AuthForm from "../Services/Authentication/AuthForm";
import Screen from "../Components/Home";
import PrivateRoute from "./PrivateRoute";
import LegalAssistant from "../Navigation/LegalAssistant/LegalAssistant";
import LegalQueryAssistant from "../Navigation/AskLegalQuery/LegalQueryAssistant";
import UserProfile from "../ProfileSection/UserProfile";
import About from "../Navigation/About/About";
import Workspace from "../Navigation/Workspace/Workspace";
import Signup from "../Services/Signup/Signup";
import FileTable from "../Components/Folders/FolderDetailsM";
import ChangePassword from "../ProfileSection/ChangePassword";  // Fixed typo from ChangePassowrd
import ResetPasswordRequest from "../ProfileSection/PasswordResetRequest";
import ResetPassword from "../ProfileSection/ResetPassword";
import AccountSettings from "../ProfileSection/AccountSettings";
import SummarizeDoc from "../Components/SummarizeDoc";
import SearchResults from "../Components/SearchResults";
import Dashboard from "../Navigation/Dashboard/Dashboard";
import ConfirmUserAccount from "../Services/Signup/Confirm";
import Plans from "../Components/Plans";

const AppRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Screen />} />
      
      {/* Private Routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/workspace" element={<Workspace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/legal-assistant" element={<LegalAssistant />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/folder/:folderId" element={<FileTable />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/summarize-doc" element={<SummarizeDoc />} />
        <Route path="/search-results" element={<SearchResults />} />
      </Route>

      {/* Public Routes */}
      <Route path="/legal-query-assistant" element={<LegalQueryAssistant />} />
      <Route path="/auth" element={<AuthForm />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/about" element={<About />} />
      <Route path="/request-password-reset" element={<ResetPasswordRequest />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/confirm" element={<ConfirmUserAccount/>} />
      <Route path="/plans" element={<Plans/>} />

      {/* Catch-all for undefined routes */}
      <Route path="*" element={<Navigate to="/about" />} />
    </Routes>
  );
};

export default AppRoutes;
