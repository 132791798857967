import React, { useEffect, useState } from 'react';
import { useAuth } from '../Services/Authentication/AuthContext';
import './LawyerDashboard.css'; // Import the provided CSS

function UserDashboard() {
  const { userDetails } = useAuth();

  // Usage & messages
  const [userUsage, setUserUsage] = useState(null);
  const [userMessages, setUserMessages] = useState([]);
  // Requests
  const [userRequests, setUserRequests] = useState([]);
  const [requestsLoading, setRequestsLoading] = useState(true);

  // Loading & error states
  const [loading, setLoading] = useState(true);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [error, setError] = useState(null);

  // 1) Fetch usage data
  useEffect(() => {
    if (!userDetails?.userId) {
      setError('Missing user ID in userDetails.');
      setLoading(false);
      return;
    }

    const fetchUsage = async () => {
      try {
        const response = await fetch(
          `https://mtt2dp3ze6.execute-api.us-east-1.amazonaws.com/dev/userquota/usage-with-plan/${userDetails.userId}`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch usage data (status: ${response.status})`);
        }

        const data = await response.json();
        setUserUsage(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsage();
  }, [userDetails]);

  // 2) Fetch user messages
  useEffect(() => {
    if (!userDetails?.userId) {
      setMessagesLoading(false);
      return;
    }

    const fetchMessages = async () => {
      try {
        const response = await fetch(
          // Replace with your real endpoint if needed
          `https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/user_messages/${userDetails.userId}`,
          {
            headers: {
              'Content-Type': 'application/json'
              // If you have auth, add: Authorization: `Bearer ${token}`
            }
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch messages (status: ${response.status})`);
        }

        const data = await response.json();
        setUserMessages(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setMessagesLoading(false);
      }
    };

    fetchMessages();
  }, [userDetails]);

  // 3) Fetch user requests (by userId)
  useEffect(() => {
    if (!userDetails?.userId) {
      setRequestsLoading(false);
      return;
    }

    const fetchUserRequests = async () => {
      try {
        const response = await fetch(
          // Adjust this URL to match your backend
          `https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/lawyer_requests/by_userid/${userDetails.userId}`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch user requests (status: ${response.status})`);
        }

        const data = await response.json();
        setUserRequests(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setRequestsLoading(false);
      }
    };

    fetchUserRequests();
  }, [userDetails]);

  // Handle loading and errors
  if (loading) {
    return <div>Loading your dashboard...</div>;
  }
  if (error) {
    return <div style={{ color: 'red' }}>Error: {error}</div>;
  }
  if (!userUsage) {
    return <div>No usage data available.</div>;
  }

  // Destructure usage data (adjust key names if your API differs)
  const {
    usedSummaries = 0,
    usedUploads = 0,
    generatedDocuments = 0,
    dailyLegalQueryLimit = 0,
    usedLegalQueries = 0
  } = userUsage;

  // Calculate remaining chat session credits
  const chatSessionCredits = dailyLegalQueryLimit - usedLegalQueries;

  // Hard-coded storage usage (or fetch from your API if available)
  const storageUsed = '7GB';
  const storageLimit = '10GB';

  // Example data for Recent Activity (still static)
  const recentActivities = [
    { date: 'Jan 24, 2025', description: '"Contract Agreement.pdf" summarized successfully' },
    { date: 'Jan 23, 2025', description: '"Lease Agreement.doc" uploaded' },
    { date: 'Jan 23, 2025', description: 'Asked about NDA arrangement terms' },
    { date: 'Jan 22, 2025', description: '"Legal Assets.pdf" summarized' }
  ];

  return (
    <div className="lawyer-dashboard-container">
      <h1 className="dashboard-title">Dashboard</h1>

      {/* KPI Boxes */}
      <div className="dashboard-kpis">
        <div className="kpi-box">
          <h2>Documents Summarized</h2>
          <p className="kpi-value">{usedSummaries}</p>
        </div>
        <div className="kpi-box">
          <h2>Documents Uploaded</h2>
          <p className="kpi-value">{usedUploads}</p>
          <div className="kpi-subinfo">
            {storageUsed}/{storageLimit} Storage Used
          </div>
        </div>
        <div className="kpi-box">
          <h2>Documents Generated</h2>
          <p className="kpi-value">{generatedDocuments}</p>
        </div>
        <div className="kpi-box">
          <h2>Chat Session Credits</h2>
          <p className="kpi-value">
            {chatSessionCredits} / {dailyLegalQueryLimit}
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="dashboard-main-content">
        {/* Left: User Requests Table */}
        <div className="dashboard-left">
          <h2>Your submitted requests</h2>
          {requestsLoading ? (
            <div>Loading your requests...</div>
          ) : userRequests.length === 0 ? (
            <div>No requests found.</div>
          ) : (
            <table className="requests-table">
              <thead>
                <tr>
                  <th>Request ID</th>
                  <th>Request Code</th>
                  <th>Description</th>
                  <th>Submission Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userRequests.map((req) => {
                  // Convert is_accepted to a user-friendly status
                  let statusText = 'Open';
                  if (req.is_accepted === 'true' || req.is_accepted === true) {
                    statusText = 'Accepted';
                  }

                  const dateStr = req.created_at
                    ? new Date(req.created_at).toLocaleDateString()
                    : '';

                  return (
                    <tr key={req.id}>
                      <td>{req.id}</td>
                      <td>{req.request_code}</td>
                      <td>{req.description}</td>
                      <td>{dateStr}</td>
                      <td>
                        <span className={`status-badge status-${statusText.toLowerCase()}`}>
                          {statusText}
                        </span>
                      </td>
                      <td>
                        <a href="#details">View Details &rarr;</a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        {/* Right: Recent Activity + Messages */}
        <div className="dashboard-right">
          <div className="recent-activity-section">
            <h2>Recent Activity</h2>
            <ul className="activity-list">
              {recentActivities.map((activity, idx) => (
                <li key={idx}>
                  <div className="activity-date">{activity.date}</div>
                  <div className="activity-desc">{activity.description}</div>
                </li>
              ))}
            </ul>
          </div>

          <div className="messages-section">
            <h2>Messages</h2>
            {messagesLoading ? (
              <div>Loading messages...</div>
            ) : userMessages.length === 0 ? (
              <div>No messages found.</div>
            ) : (
              <ul className="messages-list">
                {userMessages.map((msg) => (
                  <li key={msg.id}>
                    <div className="message-from">
                      <strong>From:</strong> {msg.lawyer_first_name} {msg.lawyer_last_name}
                    </div>
                    <div className="message-subject">
                      <strong>Subject:</strong> {msg.subject}
                    </div>
                    <div className="message-date">
                      {msg.created_at ? new Date(msg.created_at).toLocaleString() : ''}
                    </div>
                    <div className="message-text">
                      {msg.message_text}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
