import React from 'react';
import './About.css'
import InfoPanel from '../../Components/InfoPanel';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Row } from 'react-bootstrap';
import docsImage from '../../images/OlanurLogo_footer.png'
import AppTitle from '../../Components/app.title';

const About = () => {
    return (
        <div className='about-us'>
            <Container>
            <Row>
                <InfoPanel/>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                </div>
            </div>
            <Card>
      <CardMedia
        sx={{ height: 240 }}
        image={docsImage}
        title={<AppTitle />}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        About <AppTitle />
        </Typography>
        <Typography variant="body2" color="text.secondary">
        <AppTitle /> is a document management tool designed for legal professionals, helping them manage, create, and store legal documents efficiently. 
        </Typography>
      </CardContent>
      <CardActions>
        <Button sx={{maxWidth:'150px'}} size="small">Share</Button>
        <Button sx={{maxWidth:'150px'}} size="small">Learn More</Button>
      </CardActions>
    </Card>
            </Row>
        </Container>
        </div>
    );
};

export default About;
