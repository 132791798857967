import React, { createContext, useContext, useState, useEffect } from 'react';
import AuthService from './AuthService';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);  // Add loading state

  useEffect(() => {
    const initAuth = async () => {
      try {
        const currentUser = await AuthService.getCurrentUser();
        if (currentUser) {
          const userDetails = await AuthService.userProfile();
          setUser(currentUser);
          setUserDetails(userDetails);
          setAuth(true);
        } else {
          setAuth(false);
        }
      } catch (error) {
        console.error(error);
        setAuth(false);
      } finally {
        setLoading(false);
      }
    };
    initAuth();
  }, []); // Remove auth from the dependency to avoid infinite loop

  const register = async (username, email, password, firstName, lastName) => {
    try {
      const data = await AuthService.register(username, email, password, firstName, lastName);
      setError(null);
      return data;
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  const userProfile = async () => {
    try {
      const data = await AuthService.userProfile();
      setUserDetails(data);
      setError(null);
      return data;
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  const login = async (username, password) => {
    try {
      const data = await AuthService.login(username, password);
      const currentUser = await AuthService.userProfile();
      setUser(data);
      setUserDetails(currentUser);
      setAuth(true);
      setError(null);
      return data;
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setUserDetails(null);
    setAuth(false);
    AuthService.Logout();
  };

  const value = {
    user,
    userDetails,
    auth,
    loading,  // Add loading to value to handle pending auth state
    login,
    logout,
    register,
    userProfile,
    requestPasswordReset: AuthService.requestPasswordReset,
    resetPassword: AuthService.resetPassword,
    updateUser: AuthService.updateUser,
    updatePassword: AuthService.updatePassword,
    confirmUserRegistration: AuthService.confirmUserRegistration,
    error
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Only render children when not loading */}
    </AuthContext.Provider>
  );
};
