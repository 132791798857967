import React, { useEffect, useState } from 'react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import StyledButton from './StyledButton';
import { CreateNewFolder } from '@mui/icons-material';
import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import axios from 'axios';
import folders from './Data';
import FolderService from '../../Services/DocAI/FolderService';
import CustomSnackbar from '../SnackBar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CreateFolder = ({onCreateFolder}) => {

  const [open, setOpen] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFolderName('');
  };

  const handleCreate = async (event) => {
    console.log('Generate document');
    event.preventDefault();
    setLoading(true);
    try {
      const response = await FolderService.createFolder(folderName);
      showSnackbar(`Folder "${folderName}" created successfully!`, 'info')
      handleClose();
      fetchFolders();
      
    } catch (error) {
      showSnackbar('Error creating folder.','error');
    } finally {
      setLoading(false);
    }
  };

  const fetchFolders = async () => {
    onCreateFolder();
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  const TileButton = ({ title, icon, onClick }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            background: '#4a0f73',
            color: '#fff',
            borderRadius: '8px',
            padding: '16px',
            marginBottom: '16px',
            width: '150px',
            height: '100px',
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'scale(1.05)',
            },
        }}
        onClick={onClick}
    >
        {icon}
        <Typography variant="body1" sx={{ marginTop: 1 }}>
            {title}
        </Typography>
    </Box>
);

  return (
    <>
    {/* <TileButton 
                    title="Generate Document"
                    icon={<NoteAddOutlined fontSize="large"/>} 
                    onClick={()=> navigate('/legal-assistant')}
                /> */}
      <StyledButton startIcon={<CreateNewFolder />} variant="contained" sx={{ background: '#4a0f73', }} onClick={handleOpen} style={{ marginBottom: 16 }}>
        Create New Folder
      </StyledButton>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style} component="form" onSubmit={handleCreate}>
          <Typography variant="h6" component="h2">
            Create New Folder
          </Typography>
          <TextField
            label="Folder Name"
            variant="outlined"
            fullWidth
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Create'}
            </Button>
          </Box>
        </Box>
      </Modal>
      {statusMessage && (
        <Typography variant="body1" color="primary" sx={{ mt: 2 }}>
          {statusMessage}
        </Typography>
      )}
      
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>

  );
};

export default CreateFolder;
