import axiosInstance from '../../api/axiosInstance';
import { LEGAL_ASSIST_AI_NLP_SERVICE_API_URL, LEGAL_DOC_MANAGEMENT_API_URL } from '../../api/Endpoints';
import AuthService from '../Authentication/AuthService';

const DOCAI_SERVICE_API = '/documents'
const baseURL = LEGAL_DOC_MANAGEMENT_API_URL;
const DocAIService = {

  startSession: async () => {
    try{
      const userDetails = await AuthService.userProfile();
      const response = await axiosInstance.post(LEGAL_ASSIST_AI_NLP_SERVICE_API_URL + '/sessions/start_session', {
          userId: userDetails.username,
      });
      return response.data;
    } catch (error) {
      console.error('Error while starting session:', error.response || error.message);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  sendMessage: async (sessionId, message) => {
    try{
      const userDetails = await AuthService.userProfile();
      const response = await axiosInstance.post(LEGAL_ASSIST_AI_NLP_SERVICE_API_URL + '/sessions/send_message', {
          userId: userDetails.username,
          sessionId: sessionId,
          message: message,
      });
      return response.data;
    } catch (error) {
      console.error('Error while sending message:', error.response || error.message);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  listSessions: async () => {
    try{
      const userDetails = await AuthService.userProfile();
      const response = await axiosInstance.get(LEGAL_ASSIST_AI_NLP_SERVICE_API_URL + '/sessions/list_sessions?userId='+userDetails.username);
      return response.data;
    } catch (error) {
      console.error('Error while listing sessions:', error.response || error.message);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  getSession: async (sessionId) => {
    try{
      const userDetails = await AuthService.userProfile();
      const response = await axiosInstance.get(LEGAL_ASSIST_AI_NLP_SERVICE_API_URL + '/sessions/get_session?userId='+userDetails.username+'&sessionId='+sessionId);
      return response.data;
    } catch (error) {
      console.error('Error while getting session details:', error.response || error.message);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  asklegalquery: async (query) => {
    try {
      
      const response = await axiosInstance.post(LEGAL_ASSIST_AI_NLP_SERVICE_API_URL + '/operations/query-openai', {'prompt' : query});
      return response.data;
    } catch (error) {
      console.log('Error while register : '.error.response);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  summarizeDocument: async (summaryPrompt, formData) => {
    try {
      // Add UI field to prompt summary text
      const response = await axiosInstance.post(LEGAL_ASSIST_AI_NLP_SERVICE_API_URL +'/operations/generatesummary', formData, {
        params: {

          summary_type : summaryPrompt,
        },
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to summarize document', error.response);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  generateS3Url: async (summaryPrompt, formData) => {
    try {
      // Add UI field to prompt summary text
      const response = await axiosInstance.post(LEGAL_ASSIST_AI_NLP_SERVICE_API_URL +'/operations/generatesummary', formData, {
        params: {

          summary_type : summaryPrompt,
        },
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to summarize document', error.response);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },
};

export default DocAIService;
