// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {

}

.about-us textarea {
    width: 90%;
    padding: 10px;
    margin-top: 20px;
}

.about-us button {
    padding: 10px 20px;
    background-color: #4a0f73;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}

.about-us button:hover {
    background-color: #351054;
}
`, "",{"version":3,"sources":["webpack://./src/Navigation/About/About.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,UAAU;IACV,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".about-us {\n\n}\n\n.about-us textarea {\n    width: 90%;\n    padding: 10px;\n    margin-top: 20px;\n}\n\n.about-us button {\n    padding: 10px 20px;\n    background-color: #4a0f73;\n    color: white;\n    border: none;\n    cursor: pointer;\n    margin-top: 10px;\n}\n\n.about-us button:hover {\n    background-color: #351054;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
