// src/UserProfile.js
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from '../Services/Authentication/AuthContext';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const ResetPassword = () => {

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const { resetPassword, error } = useAuth();
    const [token, setToken] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            try {
                const tokenTemp = searchParams.get('token')
                console.log(tokenTemp);
                setToken(tokenTemp);
            } catch (error) {
                console.error(error);
            }
        };
        init();
    }, []);

    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: '',
    });


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('Test 123');
        // Add your save logic here, e.g., make an API call to save the data
        console.log(formData);
        setMessage('');
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            console.log('test');
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return;
        }

        if (newPassword !== confirmPassword) {
            setMessage('New passwords do not match');
            return;
        }

        try {
            console.log("Calling Reset Password");
            const response = await resetPassword(token, formData.newPassword);
            setMessage(response);
            await setTimeout(5000)
            navigate('/');

        } catch (error) {
            setMessage('Failed to update password');
        }
    };


    return (
        <Container className='user-profile text-light rounded '>
            <Row style={{ display: 'flex' }} className="text-light px-3 py-2 radius-card">
                <h3>Change Password</h3>
            </Row>
            <Row className='p-3'>
                <Col md={8} className="md-8 border-right py-10" style={{ display: 'flex' }}>
                    {message && <Alert severity="success">{message}</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                    <Form noValidate onSubmit={handlePasswordReset} validated={validated} className='justify-content-start' style={{ width: 400, maxWidth: '100%' }}>
                        <Form.Group as={Row} className="mb-3" controlId="formNewPassword">
                            <Form.Label sm="2">New Password</Form.Label>
                            <Col>
                                <Form.Control
                                    type="password"
                                    name="newPassword"
                                    placeholder='New Password'
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                    minLength={6}
                                    required
                                    isInvalid={
                                        validated && formData.newPassword.length < 6
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Password must be at least 6 characters long.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formConfirmPassword">
                            <Form.Label sm="2">Confirm New Password</Form.Label>
                            <Col>
                                <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    placeholder='Confirm New Password'
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    minLength={6}
                                    required
                                    isInvalid={
                                        validated &&
                                        formData.confirmPassword !== formData.newPassword
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Passwords do not match.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Button style={{ maxWidth: '150px' }} type='submit'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-floppy" viewBox="0 0 20 20">
                                <path d="M11 2H9v3h2z" />
                                <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                            </svg>
                            Update
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPassword;
