// src/Components/RequestBoard.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";

function RequestBoard() {
  const [openRequests, setOpenRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [draftMessage, setDraftMessage] = useState("");
  const [consultationFee, setConsultationFee] = useState("");
  const [lawyerSchedulerUrl, setLawyerSchedulerUrl] = useState("");

  // Retrieve auth token from local storage (if applicable)
  const token = localStorage.getItem("authToken") || "";
  // (Optional) Retrieve lawyerId from local storage or a global context
  // For demonstration, we default to 123 if none is found.
  const lawyerId = localStorage.getItem("lawyerId") || 123;

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch open requests
    fetch("https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/getopenrequests", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch open requests.");
        }
        return res.json();
      })
      .then((data) => {
        setOpenRequests(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, [token]);

  // Helper to format the request description
  const formatDescription = (description) => {
    let formattedText = description.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    formattedText = formattedText.replace(/(\d+)\.\s/g, "<br>⭐ <strong>$1.</strong> ");
    return formattedText;
  };

  // Opens the "View Details" dialog
  const handleViewDetails = (requestId) => {
    const requestDetails = openRequests.find((req) => req.id === requestId);
    setSelectedRequest(
      requestDetails || {
        id: requestId,
        request_code: "REQ-000X",
        first_name: "John",
        last_name: "Doe",
        email: "johndoe@example.com",
        description: "This is a sample request description.",
      }
    );
    setIsDetailsDialogOpen(true);
  };

  // Closes the "View Details" dialog
  const handleCloseDetailsDialog = () => {
    setIsDetailsDialogOpen(false);
    setSelectedRequest(null);
  };

  // When lawyer clicks "Interested" from the details dialog
  const handleInterested = (requestId) => {
    setIsDetailsDialogOpen(false);

    // Example: fetch the lawyer's booking link from your backend
    // If you store it in DB or user profile, replace this URL accordingly
    fetch(`http://localhost:5000/getLawyerSchedulerUrl`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLawyerSchedulerUrl(data.scheduler_url || "https://example.com/lawyer-booking");
      })
      .catch(() => {
        // Fallback if there's an error
        setLawyerSchedulerUrl("https://example.com/lawyer-booking");
      });

    // Set a default draft message (including the booking link)
    setDraftMessage(
      `Hello,\n\nI have reviewed your request and would be happy to assist. Please book a time that suits you using the link below:\n\n${lawyerSchedulerUrl}\n\nLooking forward to our discussion.`
    );

    setIsMessageDialogOpen(true);
  };

  // Closes the "Send Message" dialog
  const handleCloseMessageDialog = () => {
    setIsMessageDialogOpen(false);
  };

  // Sends the message & confirms interest
  const handleSendMessage = () => {
    // Build the payload for the confirminterest endpoint
    const payload = {
      requestId: selectedRequest.id,
      lawyerId: Number(lawyerId), // ensure it's a number
      consultationFee: Number(consultationFee),
      message: draftMessage,
      userEmail: selectedRequest.email
    };

    fetch("https://ybhx1gozcd.execute-api.us-east-1.amazonaws.com/ainlp_dev/confirmrequests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // If your API requires auth
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to confirm interest. Please check the request and try again.");
        }
        return res.json();
      })
      .then((data) => {
        alert("Message sent to user. Interest confirmed!");
        handleCloseMessageDialog();
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", color: "#fff" }}>
        Open Requests
      </Typography>

      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell><strong>ID</strong></TableCell>
                <TableCell><strong>Request Code</strong></TableCell>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Email</strong></TableCell>
                <TableCell><strong>Description</strong></TableCell>
                <TableCell><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {openRequests.map((request) => (
                <TableRow hover key={request.id}>
                  <TableCell>{request.id}</TableCell>
                  <TableCell>{request.request_code}</TableCell>
                  <TableCell>{request.first_name} {request.last_name}</TableCell>
                  <TableCell>{request.email}</TableCell>
                  <TableCell>{request.description?.substring(0, 100)}...</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={() => handleViewDetails(request.id)}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* First Modal - View Request Details */}
      <Dialog open={isDetailsDialogOpen} onClose={handleCloseDetailsDialog}>
        <DialogTitle>Request Details</DialogTitle>
        <DialogContent>
          {selectedRequest && (
            <>
              <Typography><strong>ID:</strong> {selectedRequest.id}</Typography>
              <Typography><strong>Request Code:</strong> {selectedRequest.request_code}</Typography>
              <Typography><strong>Name:</strong> {selectedRequest.first_name} {selectedRequest.last_name}</Typography>
              <Typography><strong>Email:</strong> {selectedRequest.email}</Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: formatDescription(selectedRequest.description) }}
                sx={{ mt: 1, whiteSpace: "pre-line" }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailsDialog} color="secondary">
            Not Interested
          </Button>
          <Button onClick={() => handleInterested(selectedRequest.id)} color="primary" variant="contained">
            Interested
          </Button>
        </DialogActions>
      </Dialog>

      {/* Second Modal - Draft Message & Booking */}
      <Dialog open={isMessageDialogOpen} onClose={handleCloseMessageDialog}>
        <DialogTitle>Send Message to User</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Consultation Fee ($) per hour"
            variant="outlined"
            margin="dense"
            value={consultationFee}
            onChange={(e) => setConsultationFee(e.target.value)}
          />
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            variant="outlined"
            margin="dense"
            value={draftMessage}
            onChange={(e) => setDraftMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSendMessage} color="primary" variant="contained">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RequestBoard;
