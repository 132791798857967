import React from 'react';
import SearchCard from './SearchCard';
import { Col, Container, Row, Carousel } from 'react-bootstrap';
import { useAuth } from '../Services/Authentication/AuthContext';
import chatbotScreenshot1 from '../images/Capture.PNG'; 
import chatbotScreenshot2 from '../images/Capture.PNG'; 
import chatbotScreenshot3 from '../images/Capture.PNG'; 
import StyledButton from './Folders/StyledButton';
import './Home.css';
import { useNavigate } from 'react-router-dom';

const styles = {
  Screen: {
    backgroundColor: '#161616',
  },
  ChatbotPanel: {
    padding: '0px 0px 25px 0px',
    color: 'white',
    backgroundColor: 'transparent',
  },
  ChatbotPanelHeader: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  ScreenshotImage: {
    height: '400px',
    width: '100%',
    borderRadius: '10px',
  },
  CarouselContainer: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    margin: '0 auto',
  },
  CarouselItem: {
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
  VideoContainer: {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    backgroundColor: 'transparent',
    marginTop: '30px',
  },
  Video: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  Disclaimer: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f8f9fa',
    color: '#333',
    textAlign: 'center',
    fontSize: '14px',
    borderRadius: '5px',
  },
};

const Screen = (props) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleJoinNow = () => {
    navigate('/auth?signup')
  }


  return (
    <>
      <Container fluid>
        <Row>
          <Col className="mb-3">
            <SearchCard />
          </Col>
        </Row>
        {/* Video Section with Transparent Background */}
        {/* <Row>
          <Col xs={12} style={styles.VideoContainer}>
            <video style={styles.Video} autoPlay loop muted playsInline>
              <source src={transparentVideo} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Col>
        </Row> */}

        {/* Carousel Section */}
        <Row style={styles.ChatbotPanel}>
          {/* <Col xs={12} style={styles.ChatbotPanelHeader}>
            <h2>Legal Assistant Chatbot</h2>
            <p>Explore the features of our AI-powered legal assistant chatbot. See how it can simplify legal queries with the screenshots below.</p>
          </Col> */}
          

          <Col xs={12} style={styles.CarouselContainer}>
            <Carousel controls={false} indicators={false} interval={3000} pause={false} slide>
              <Carousel.Item style={styles.CarouselItem}>
                <img
                  src={chatbotScreenshot1}
                  alt="Legal Assistant Chatbot Screenshot 1"
                  style={styles.ScreenshotImage}
                />
              </Carousel.Item>
              <Carousel.Item style={styles.CarouselItem}>
                <img
                  src={chatbotScreenshot2}
                  alt="Legal Assistant Chatbot Screenshot 2"
                  style={styles.ScreenshotImage}
                />
              </Carousel.Item>
              <Carousel.Item style={styles.CarouselItem}>
                <img
                  src={chatbotScreenshot3}
                  alt="Legal Assistant Chatbot Screenshot 3"
                  style={styles.ScreenshotImage}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        {!user && <Row className="cta-section text-center">
        <Col>
          <h2 className="cta-title">Ready to Simplify Your Legal Work?</h2>
          <p className="cta-description">Join thousands of professionals who rely on our AI-powered platform for fast, secure, and effective legal services.</p>
          {/* <StyledButton variant="success" className="cta-button">Sign Up Now</StyledButton> */}
          
          <StyledButton onClick={handleJoinNow} variant="contained" sx={{  fontSize:'calc(6px + 2vmin);', borderRadius:'30px', height: '55px', width:'200px', background: '#198754' }} className="hero-button">Sign up now</StyledButton>
        </Col>
      </Row>}
{/* Disclaimer Section */}
<Row>
          <Col>
            <div style={styles.Disclaimer}>
              <p><strong>Disclaimer:</strong> The information and tools provided by this software are for informational purposes only and are not intended as legal advice. While the software may assist in the drafting, summarization, or organization of legal documents, it does not replace professional legal counsel. Users should consult with a qualified attorney or legal professional for advice or guidance specific to their situation. The use of this software does not create an attorney-client relationship.</p>
            </div>
          </Col>
        </Row>
        
      </Container>
    </>
  );
};

export default Screen;
