import React, { useEffect, useState } from 'react';
import './Navbar.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import myLogo from '../images/icon.png';
import { InputGroup, FormControl } from 'react-bootstrap';
import { useAuth } from '../Services/Authentication/AuthContext';
import NavProfileActionsMenu from '../Components/NavProfileActionsMenu';
import StyledButton from '../Components/Folders/StyledButton';
import { Person } from '@mui/icons-material';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate, Link } from 'react-router-dom';



function NavigationLB() {
  const { user, userDetails } = useAuth();
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [navbarBg, setNavbarBg] = useState(false);

  // Determine if user is a lawyer
  let isLawyer = false;
  if (userDetails && typeof userDetails.isLawyer !== 'undefined') {
    isLawyer = userDetails.isLawyer;
  } else if (user) {
    try {
      const decoded = jwtDecode(user);
      isLawyer = decoded.isLawyer;
    } catch (err) {
      console.error('Error decoding token in NavigationLB:', err);
    }
  }

  // Change navbar background after scrolling
  const handleScroll = () => {
    setNavbarBg(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Trigger search on Enter
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && query.trim()) {
      navigate(`/search-results?q=${encodeURIComponent(query)}`);
    }
  };

  // Nav links with optional "auth" and "lawyerOnly"
  const navLinks = [
    { path: '/User-dashboard', label: 'Dashboard', auth: true, lawyerOnly: false },
    { path: '/lawyer-dashboard', label: 'Dashboard', auth: true, lawyerOnly: true },
    { path: '/workspace', label: 'Workspace', auth: true },
    { path: '/legal-assistant', label: 'Document Generator', auth: true },
    { path: '/legal-query-assistant', label: 'Legal Assistant' },
    // Only show these if user is a lawyer
    { path: '/request-board', label: 'Open Requests', auth: true, lawyerOnly: true }
    
    // Only show this if user is NOT a lawyer
    
  ];

  return (
    <Navbar
      expand="lg"
      className={`navbar navbar-expand-lg navbar-light bd-navbar sticky-top ${
        navbarBg ? 'scrolled' : ''
      }`}
    >
      <Container fluid className="container-xxl bd-gutter flex-wrap flex-lg-nowrap">
        {/* Use as={Link} for in-app navigation */}
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img
            alt="Logo"
            src={myLogo}
            width="78"
            height="54.8"
            className="d-inline-block align-center"
          />
          {/* Removed position:absolute; replaced with a simple Bootstrap margin-left */}
          <span
            className="ms-2"
            style={{
              fontSize: '13px',
              fontWeight: 300,
              color: 'wheat',
            }}
          >
            Early Release
          </span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll" style={{ height: '100%' }}>
          <Nav className="ms-auto my-0 my-lg-0" style={{ paddingRight: '10px' }} navbarScroll>
            {navLinks.map((link) => {
              // Hide link if requires auth but user not logged in
              if (link.auth && !user) return null;

              // If link is lawyerOnly=true, only show if isLawyer; if lawyerOnly=false, show if not lawyer
              if (typeof link.lawyerOnly === 'boolean') {
                if (link.lawyerOnly && !isLawyer) return null;
                if (!link.lawyerOnly && isLawyer) return null;
              }

              return (
                <Nav.Link
                  as={Link}
                  key={link.path}
                  to={link.path}
                  className={location.pathname === link.path ? 'active' : ''}
                >
                  {link.label}
                </Nav.Link>
              );
            })}
          </Nav>

          {/* Only show the search bar if user is logged in */}
          {user && (
            <Nav className="d-flex ms-2 px-2">
              <InputGroup>
                <InputGroup.Text id="search">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </InputGroup.Text>
                <FormControl
                  style={{ fontSize: '14px', fontWeight: 300 }}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyUp={handleKeyPress}
                  maxLength={50}
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search"
                />
              </InputGroup>
            </Nav>
          )}

          {/* Right side: user profile or login button */}
          {user ? (
            <Nav>
              <NavProfileActionsMenu />
            </Nav>
          ) : (
            <Nav>
              <StyledButton
                startIcon={<Person />}
                variant="contained"
                sx={{ background: '#198754' }}
                className="button"
                href="/auth"
              >
                Login
              </StyledButton>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationLB;