import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, FormGroup, InputGroup } from 'react-bootstrap';
import '../Authentication/auth-form.css';
import { useAuth } from '../Authentication/AuthContext';
import { Eye, EyeFill, EyeSlash, EyeSlashFill } from 'react-bootstrap-icons';

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [validated, setValidated] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [authForm, setFormData] = useState({
    username: '',
    password: '',
  });


  const navigate = useNavigate();
  const { login, error } = useAuth();


  // Handles updating form state as the user types in inputs
  const handleChange = (e) => {
    setMessage('')
    setFormData({ ...authForm, [e.target.name]: e.target.value });
  };

  // Handles the login submission
  const handleLogin = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    console.log('Login:', authForm);

    try {
      await login(authForm.username, authForm.password);
      navigate('/workspace');
    } catch (error) {
      error.message = 'Please enter valid username and password'
      setMessage(error)
      console.error('Login failed:', error);
    }
  };

  return (
    // <Container fluid>
    //   <Row className="justify-content-md-end">
    //     <Col>

    <Form noValidate validated={validated} onSubmit={handleLogin}>

      <div className='text-center text-light'>
        <h4 className='py-2 radius-card'>{'Login'}</h4>
      </div>
      <Form.Group controlId="username">

        <Form.Control placeholder='Username'
          type="text"
          name="username"
          value={authForm.username}
          onChange={handleChange}
          pattern="^[a-zA-Z0-9]+$"
          required
          isInvalid={
            validated &&
            !/^[a-zA-Z0-9]+$/.test(authForm.username)
          }
        />
        <Form.Control.Feedback className="invalid-feedback" type="invalid">
          Please enter a valid username.
        </Form.Control.Feedback>
      </Form.Group>
      <FormGroup controlId="password">
        <InputGroup>

          <Form.Control
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder='Password'
            value={authForm.password}
            onChange={handleChange}
            minLength={1}
            required
            isInvalid={
              validated && authForm.password.length < 1
            }
          />
          <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
            {showPassword ? <EyeSlashFill color='#4a0f73' /> : <EyeFill color='#4a0f73' />}
          </InputGroup.Text>
          <Form.Control.Feedback type="invalid">
          Please enter password
        </Form.Control.Feedback>  
        </InputGroup>
        
      </FormGroup>

      {/* <Form.Group controlId="password" className="mb-3">
      </Form.Group>
      <Form.Group controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          placeholder='Password'
          value={authForm.password}
          onChange={handleChange}
          minLength={1}
          required
          isInvalid={
            validated && authForm.password.length < 1
          }
        />
        <Form.Control.Feedback type="invalid">
          Please enter password
        </Form.Control.Feedback>
      </Form.Group> */}
      {error && <p>{error.message}</p>}
      <Button type='submit'>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person" viewBox="0 0 20 20">
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
        </svg>
        Login</Button>

    </Form>

    //       </Col>
    //    </Row>
    // </Container>


  );
}

export default Login;
