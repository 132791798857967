// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.hero-section {
  height: 65vh;
  /* background-image: url('../images/docs.jpeg'); Background image */
  background-size: cover;
  background-position: center;
  color: wheat;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.hero-button {
  width: auto;
  padding: 10px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
}

.features-section {
  padding: 60px 30px;
  background-color: #f7f7f7;
}

.feature-card {
  margin-bottom: 40px;
}

.feature-title {
  font-size: 1.8rem;
  margin-top: 20px;
}

.feature-description {
  font-size: 1rem;
  color: #555;
}

.feature-image {
  max-width: 100%;
  height: auto;
}

.cta-section {
  padding: 80px 30px;
  background-color: #222;
  color: white;
}

.cta-title {
  font-size: 3rem;
  font-weight: 600;
}

.cta-description {
  font-size: 1.2rem;
  margin: 20px 0;
}

.cta-button {
  padding: 10px 40px;
  font-size: 1.2rem;
  border-radius: 50px;
  background-color: #28a745;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SearchCard.css"],"names":[],"mappings":";;AAEA;EACE,YAAY;EACZ,mEAAmE;EACnE,sBAAsB;EACtB,2BAA2B;EAC3B,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["\n\n.hero-section {\n  height: 65vh;\n  /* background-image: url('../images/docs.jpeg'); Background image */\n  background-size: cover;\n  background-position: center;\n  color: wheat;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.hero-title {\n  font-size: 3rem;\n  font-weight: 700;\n}\n\n.hero-subtitle {\n  font-size: 1.5rem;\n  margin-bottom: 20px;\n}\n\n.hero-button {\n  width: auto;\n  padding: 10px 30px;\n  font-size: 1.2rem;\n  border-radius: 50px;\n}\n\n.features-section {\n  padding: 60px 30px;\n  background-color: #f7f7f7;\n}\n\n.feature-card {\n  margin-bottom: 40px;\n}\n\n.feature-title {\n  font-size: 1.8rem;\n  margin-top: 20px;\n}\n\n.feature-description {\n  font-size: 1rem;\n  color: #555;\n}\n\n.feature-image {\n  max-width: 100%;\n  height: auto;\n}\n\n.cta-section {\n  padding: 80px 30px;\n  background-color: #222;\n  color: white;\n}\n\n.cta-title {\n  font-size: 3rem;\n  font-weight: 600;\n}\n\n.cta-description {\n  font-size: 1.2rem;\n  margin: 20px 0;\n}\n\n.cta-button {\n  padding: 10px 40px;\n  font-size: 1.2rem;\n  border-radius: 50px;\n  background-color: #28a745;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
