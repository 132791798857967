import React from 'react';
import './InfoPanel.css'; // Ensure the CSS is imported
import AppTitle from './app.title';

function InfoPanel() {
  return (
    <div className="info-panel">
      <h5 className="legal-docs-pro-header">Revolutionizing Legal Services with</h5>
      <h5 className="legal-docs-pro-header">Olanur</h5>
      <p className="subtitle">Discover the Power of AI in Legal Services with Olanur</p>
      {/* Include any other elements or components as needed */}
    </div>
  );
}

export default InfoPanel;
