import React, { useState, useEffect, useRef } from 'react';
import { Grid, TextField, Card, Snackbar, Box, CircularProgress, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, Alert } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import { styled } from '@mui/system';
import DocAIService from '../../Services/DocAI/DocAIService';
import InfoIcon from '@mui/icons-material/Info';
import StyledButton from '../../Components/Folders/StyledButton';
import { SendArrowUpFill } from 'react-bootstrap-icons';

const CustomAccordionSummary = styled(({ expanded, ...props }) => (
  <AccordionSummary {...props} />
))(({ theme, expanded }) => ({
  backgroundColor: '#320d52',
  color: '#ffffff',
  borderRadius: '10px 10px 0 0',
  padding: '0px 0px',
  boxShadow: !expanded ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '0px 2px 4px rgba(0, 0, 0, 0.1)',
  border: !expanded ? 'none' : '1px solid rgba(0, 0, 0, 0.2)',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#ffffff',
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: '#4a0f733b',
  color: '#ffffff',
  borderRadius: '0px 0px 10px 10px',
  padding: '15px',
  maxHeight: '300px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
}));

const ChatBubble = styled(Paper)(({ isUser }) => ({
  backgroundColor: isUser ? '#dcf8c6' : '#edf2f7',
  color: isUser ? '#2d3748' : '#2d3748',
  padding: '10px 15px',
  borderRadius: '20px',
  margin: '10px',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  maxWidth: '70%',
  width: 'fit-content',
  wordBreak: 'break-word',
}));

const ChatContainer = styled(Card)(({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 100px)',
  overflowY: 'hidden',
}));

const ChatContent = styled(Box)(({
  flex: 1,
  overflowY: 'auto',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const ChatInputContainer = styled(Box)(({
  padding: '10px',
  backgroundColor: '#f7fafc',
  borderTop: '1px solid #e2e8f0',
}));

const ContactCard = styled(Card)(({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  marginBottom: '10px',
  backgroundColor: '#4a0f7361',
  borderRadius: '10px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  '&:last-child': {
      marginBottom: 0,
  },

}));

const ContactImage = styled('img')({
  width: '80px',
  height: '80px',
  borderRadius: '50%',
  marginRight: '15px',
});

const ContactDetails = styled(Box)({
  flex: 1,
});

const CustomAccordionItem = styled(Typography)(({ theme }) => ({
  backgroundColor: '#4a0f7361',
  borderRadius: '10px',
  marginBottom: '5px',
  padding: '15px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  '&:last-child': {
      marginBottom: 0,
  },
}));


function LegalQueryAssistant() {
  const [query, setQuery] = useState('');
  const [sessionIdCurrent, setSessionIdCurrent] = useState();
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(''); 
  const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // Snackbar severity ('success', 'error', 'warning', 'info')
  const [lawyers, setLawyers] = useState([]);
  const chatEndRef = useRef(null);
  const [expandedPanel, setExpandedPanel] = useState('panel2');
  const [chatHistoryTitle, setChatHistoryTitle] = useState([]);
  
  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };


  const handleValidation = () => {
    if (!query.trim()) {
      showSnackbar('Please enter your query.', 'warning');
      return false;
    }
    return true;
  };

  const handleAccordionToggle = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const startSession = async () => {
    try {
      const data = await DocAIService.startSession();
      setSessionIdCurrent(data.sessionId);
      await fetchChatSessions();
      return data.sessionId;
    } catch (error) {
      console.error('Error starting session:', error);
      throw error.response ? error.response.data : new Error('Network Error');
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!handleValidation()) return;

    setLoading(true);
    const userMessage = { message: query, isUser: true };
    setChatHistory([...chatHistory, userMessage]);

    try {
      let currentSessionId = sessionIdCurrent;
      if (!currentSessionId) {
        currentSessionId = await startSession();
      }
      
      const apiResponse = await DocAIService.sendMessage(currentSessionId, query);
      const responseMessage = { message: apiResponse.response, isUser: false };

      setChatHistory((prevHistory) => [...prevHistory, responseMessage]);
      setLawyers([ 
        {
          name: 'Test 1, Test2',
          contact: 'test@example.com',
          specialization: 'Criminal Law',
          description: 'Expert in criminal defense and legal representation.',
          photo: 'https://via.placeholder.com/150',
        },
        {
          name: 'Test 1, Test2',
          contact: 'test@example.com',
          specialization: 'Family Law',
          description: 'Specializes in divorce, custody, and family-related legal matters.',
          photo: 'https://via.placeholder.com/150',
        },
      ]);
    } catch (error) {
      showSnackbar('Error fetching response. Please try again later.', 'error');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setQuery('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit(event);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      document.getElementById('chat-window').style.height = `${window.innerHeight - 100}px`; // Updated height calculation
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }, [chatHistory]);

  
  const fetchChatSessions = async () => {
    try {
      
      const data = await DocAIService.listSessions();

      const sessions = data.sessions || [];
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);

      const categorizedSessions = {
        today: [],
        previous7Days: [],
        previous30Days: [],
      };
      sessions.forEach((session) => {
        const timestamp = session.latestTimestamp ? new Date(session.latestTimestamp) : null;

        if (timestamp) {
          if (timestamp.toDateString() === today.toDateString()) {
            categorizedSessions.today.push(session);
          } else if (timestamp >= sevenDaysAgo) {
            categorizedSessions.previous7Days.push(session);
          } else if (timestamp >= thirtyDaysAgo) {
            categorizedSessions.previous30Days.push(session);
          } else {
            categorizedSessions.previous30Days.push(session);
          }
        } else {
          categorizedSessions.previous30Days.push(session);
        }
      });

      Object.keys(categorizedSessions).forEach((key) => {
        categorizedSessions[key] = categorizedSessions[key].sort((a, b) => {
          const timeA = new Date(a.latestTimestamp || 0).getTime();
          const timeB = new Date(b.latestTimestamp || 0).getTime();
          return timeB - timeA; // Sort descending
        });
      });
      setChatHistoryTitle(categorizedSessions);

    } catch (error) {
      console.error("Error fetching chat history titles", error);
    }
  };


  const fetchChatHistory = async (sessionId) => {
    try {

      const sessionDetails = await DocAIService.getSession(sessionId);
      const { interactions } = sessionDetails;
      setChatHistory([]);
      if (interactions?.length > 0) {
        const formattedHistory = interactions.flatMap((interaction) => [
          { message: interaction.user_message, isUser: true },
          { message: interaction.bot_response, isUser: false },
        ]);
        
        setChatHistory(() => [...formattedHistory]);
        setSessionIdCurrent(sessionId);
      } else {
        setChatHistory([]);
      }
    } catch (error) {
      console.error("Error fetching session details:", error);
      setChatHistory([]);
    }
  };
  

  useEffect(() => {
    fetchChatSessions();
    // if (sessionIdCurrent) {
    //   fetchChatHistory(sessionIdCurrent);
    // }
  }, []);

  const renderSessions = (sessions, label) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="p" sx={{ pb: 1, mb: 0 }}>
        {label}
      </Typography>
      {sessions && sessions.length > 0 ? (
        sessions.map((session) => (
          <Box
            key={session.id}
            sx={{
              padding: '10px',
              backgroundColor: session.id === sessionIdCurrent ? '#320d52' : '#a099a461',
              color: session.id === sessionIdCurrent ? '#ffffff' : '#ffffff',
              borderRadius: '5px',
              marginBottom: '5px',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: session.id === sessionIdCurrent ? '' : '#8f8f8f',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transform: 'scale(1.02)',
              },
            }}
            onClick={() => fetchChatHistory(session.id)}
          >
            <Typography variant="body1">{session.title || session.id}</Typography>
          </Box>
        ))
      ) : (
        <Typography sx={{
          padding: '10px',
          backgroundColor: '#a099a461',
          color: '#ffffff',
          borderRadius: '5px',
          marginBottom: '5px',
          cursor: 'pointer',
          // transition: 'all 0.3s ease',
          '&:hover': {
            // backgroundColor: '#8f8f8f',
            // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
        }}>No chats found</Typography>
      )}
    </Box>
  );

  const AnimatedMessage = ({ message }) => (
    <TypeAnimation
      sequence={[message, 3000]}
      wrapper="span"
      speed={{ type: "keyStrokeDelayInMs", value: 10 }}
      omitDeletionAnimation
      cursor={false}
    />
  );

  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} md={4}>
        <Accordion defaultExpanded expanded sx={{ background: 'transparent', borderRadius: '10px' }}>
          <CustomAccordionSummary
            aria-controls="chat-history-title-content"
            id="chat-history-title-header"
            expanded={true}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Typography variant="h5">
                Chat History
              </Typography>
            </Box>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            {/* <Box sx={{ mb: 2 }}>
              <Typography variant="p" sx={{ pb: 1, mb: 2 }}>
                Today
              </Typography>

              {chatHistoryTitle.length > 0 ? (
                chatHistoryTitle.slice().reverse().map((session, index) => (
                    <Box
                        key={session.id}
                        sx={{
                            padding: '10px',
                            backgroundColor: '#a099a461',
                            borderRadius: '5px',
                            marginBottom: '5px',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#8f8f8f',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                transform: 'scale(1.02)',
                            },
                        }}
                        onClick={() => fetchChatHistory(session.id)}
                    >
                        <Typography variant="body1">{session.title || `${session.id}`}</Typography>
                    </Box>
                ))
            ) : (
                <Typography>No chats found</Typography>
            )}
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="p" sx={{ pb: 1, mb: 2 }}>
                Previous 7 Days
              </Typography>
              {(chatHistoryTitle.previous7Days )&& (chatHistoryTitle.previous7Days.length > 0) ? (
                chatHistoryTitle.previous7Days.map((chat, index) => (
                  <Box key={index} sx={{ padding: '10px', backgroundColor: '#a099a461', borderRadius: '5px', marginBottom: '5px' }}>
                    <Typography variant="body1">{chat.title}</Typography>
                  </Box>
                ))
              ) : (
                <Typography>No chats found in the previous 7 days</Typography>
              )}
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="p" sx={{ pb: 1, mb: 2 }}>
                Previous 30 Days
              </Typography>
              {(chatHistoryTitle.previous30Days) && (chatHistoryTitle.previous30Days.length > 0) ? (
                chatHistoryTitle.previous30Days.map((chat, index) => (
                  <Box key={index} sx={{ padding: '10px', backgroundColor: '#a099a461', borderRadius: '5px', marginBottom: '5px' }}>
                    <Typography variant="body1">{chat.title}</Typography>
                  </Box>
                ))
              ) : (
                <Typography>No chats found in the previous 30 days</Typography>
              )}
            </Box> */}

            <Box>
              {renderSessions(chatHistoryTitle.today, 'Today')}
              {renderSessions(chatHistoryTitle.previous7Days, 'Previous 7 Days')}
              {renderSessions(chatHistoryTitle.previous30Days, 'Previous 30 Days')}
            </Box>
          </CustomAccordionDetails>
        </Accordion>
          <Accordion defaultExpanded expanded sx={{ background: 'transparent', borderRadius: '10px' }}>
            <CustomAccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              expanded={expandedPanel === 'panel1'}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <InfoIcon sx={{ marginRight: 1 }} />
                <Typography variant='h5'>
                  Lawyer Contact List
                </Typography>
              </Box>

            </CustomAccordionSummary>
            <CustomAccordionDetails>
              {lawyers.length === 0 ? (
                <CustomAccordionItem>
                  No lawyers found. Please submit your query to view contact details.
                </CustomAccordionItem>
              ) : (
                lawyers.map((lawyer, index) => (
                  <ContactCard key={index} sx={{color:'white'}}>
                    <ContactImage src={lawyer.photo} alt={lawyer.name} />
                    <ContactDetails>
                      <Typography variant="h6">{lawyer.name}</Typography>
                      <Typography variant="body2">
                        {lawyer.specialization}
                      </Typography>
                      <Typography variant="body2">
                        {lawyer.contact}
                      </Typography>
                      <Typography variant="body2">
                        {lawyer.description}
                      </Typography>
                    </ContactDetails>
                  </ContactCard>
                ))
              )}
            </CustomAccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={8}>
          <ChatContainer id="chat-window">
            {chatHistory.length === 0 ? (<Typography
              variant="h4"
              color="textSecondary"
              align="center"
              sx={{
                mt: 3,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Ensures it takes full height of parent
              }}
            >
                Enter a prompt to start chatting
              </Typography>) : (

          <ChatContent>
            {chatHistory.length === 0 ? (
              <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              sx={{
                mt: 3,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Ensures it takes full height of parent
              }}
            >
                Enter a prompt to start chatting
              </Typography>
            ) : (
              chatHistory.map((chat, index) => {
                if (!chat) {
                  window.alert(chat.message); // Trigger an alert for bot messages
                }
                return (<ChatBubble key={index} isUser={chat.isUser}>
                  {chat.isUser ? (
                    <Typography variant="body1">{chat.message}</Typography>
                  ) : (

                    

                    <TypeAnimation
                    key={`${chat.message}-${index}`} // Add unique key
                    sequence={[chat.message, 3000]}
                    wrapper="span"
                    speed={{ type: "keyStrokeDelayInMs", value: 10 }}
                    omitDeletionAnimation
                    cursor={false}
                    />
                  )}
                </ChatBubble>)
              })
            )}
            {/* Keep this div for scrolling */}
            <div ref={chatEndRef} />
          </ChatContent>)}
            <ChatInputContainer>
              <form onSubmit={onSubmit} style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label="Type your message..."
                  multiline
                  rows={1}
                  fullWidth
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                  variant="outlined"
                  InputLabelProps={{ style: { color: '#4a5568' } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#e2e8f0',
                      },
                      '&:hover fieldset': {
                        borderColor: '#90cdf4',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#3182ce',
                      },
                    },
                  }}
                />
                <Box ml={3}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20} /> : <SendArrowUpFill/>}
                  >
                    Send
                  </StyledButton>
                </Box>
              </form>
            </ChatInputContainer>
          </ChatContainer>
        </Grid>
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default LegalQueryAssistant;
