import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  TextField,
  ListItemText,
  Alert,
  Box,
  Divider,
  CircularProgress,
  IconButton,
  Tooltip,
  Grid,
  Snackbar,
  Icon,
  Card,
  CardContent,
  CardMedia
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ExtensionIcon from '@mui/icons-material/Extension';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { styled } from '@mui/system';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import axiosInstance from '../../api/axiosInstance';
import DocAIService from '../../Services/DocAI/DocAIService';
import { useNavigate } from 'react-router-dom';
import { ArrowBack, ArrowLeftSharp, ArrowRight, ArrowRightRounded, ArrowRightSharp, CloudUpload, DescriptionRounded, DocumentScanner, DocumentScannerOutlined, DocumentScannerRounded, Image, InsertDriveFileRounded, VideoCameraFrontRounded } from '@mui/icons-material';
import StyledButton from '../../Components/Folders/StyledButton';
import './wizard.css'
import CustomSnackbar from '../../Components/SnackBar';
import FolderService from '../../Services/DocAI/FolderService';

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(0),
  borderRadius: theme.spacing(1),
}));

// Custom Step content background color
const CustomContentBox = styled(Paper)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#1d0f734f',
  borderRadius: '10px',
  marginBottom: '5px',
  padding: '15px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  '&:last-child': {
    marginBottom: 0,
  },
}));

const CustomListItemSummary = styled(ListItem)(({ theme }) => ({
  backgroundColor: '#c5dde33b',
  borderRadius: '10px',
  marginBottom: '5px',
  //padding: '15px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  '&:last-child': {
    marginBottom: 0,
  },
}));

const CustomListItem = styled(ListItem)(({ theme, randomColor, isSelected }) => ({
  backgroundColor: randomColor || '#c5dde33b',
  borderRadius: '10px',
  marginBottom: '5px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  width: '275px',
  height: '275px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', 
  alignItems: 'center',
  cursor: 'pointer',
  border: isSelected ? '4px solid white' : 'none', 
  transition: 'all 0.3s ease',
  '&:hover': {
    filter: 'brightness(1.1)', 
  },
}));

const iconMapping = {
  'Policy Documents': <InsertDriveFileRounded sx={{ color: 'white', fontSize: 70 }} />,
  'Contracts and Agreements': <DescriptionRounded sx={{ color: 'white', fontSize: 70 }} />,
  'Personal Consultation document Types': <Image sx={{ color: 'white', fontSize: 70 }} />,
  'Dispute Resolution Document Types': <VideoCameraFrontRounded sx={{ color: 'white', fontSize: 70 }} />,
};

// Define steps
const steps = ["Document Type", "Document Selection", "Details", "Review and Submit"];

// Data for document types and details
const data = {
  "Contracts and Agreements": [
    "Freelance Agreements",
    "Non Disclosure Agreements",
    "Employment Agreement",
    "Shareholding Agreement",
    "Partnership Agreement",
    "Property Lease Agreement"
  ],
  "Policy Documents": {
    "Code of Conduct": {
      keyComponents: "Ethical guidelines, Professional behavior standards, Compliance with legal requirements"
    },
    "Internet and Email Usage Policy": {
      keyComponents: "Acceptable and prohibited uses, Security guidelines, Consequences of policy violations"
    },
    "Health and Safety Policy": {
      keyComponents: "Compliance with health and safety laws, Procedures for hazards, Emergency response guidelines"
    },
    "Privacy Policy": {
      keyComponents: "Types of data collected, Data usage and sharing, Compliance with data protection laws"
    },
    "Equal Opportunity Policy": {
      keyComponents: "Non-discrimination clauses, Inclusivity and diversity promotion, Procedures for handling complaints"
    }
  },
  "Personal Consultation document Types": {
    "Consultation Agreement": {
      purpose: "Outlines the terms of consultation services.",
      keyComponents: "Consultant and client details, services provided, fees, duration, confidentiality, and termination conditions."
    },
    "Intake Form": {
      purpose: "Gathers essential client information.",
      keyComponents: "Personal information, contact details, specific needs or concerns, relevant personal or medical history."
    },
    "Consent Forms": {
      purpose: "Ensures informed client agreement to the consultation.",
      keyComponents: "Description of services, potential risks, benefits, and client consent signature."
    },
    "Service Plan": {
      purpose: "Details the strategy for the client's needs.",
      keyComponents: "Objectives, detailed actions, expected outcomes, timelines."
    },
    "Progress Notes": {
      purpose: "Records details of each consultation session.",
      keyComponents: "Session date, topics discussed, advice given, plans for future sessions."
    },
    "Confidentiality Agreement": {
      purpose: "Secures privacy of shared information.",
      keyComponents: "Statement of confidentiality, scope of information covered, obligations and exceptions, signatures."
    },
    "Invoice": {
      purpose: "Bills for consultation services.",
      keyComponents: "List of services, dates, costs per service, total amount due, payment terms, payment methods."
    },
    "Follow-up Plan": {
      purpose: "Guides client post-consultation.",
      keyComponents: "Steps for the client to take after consultation ends, follow-up appointments, additional resources or activities."
    },
    "Feedback Form": {
      purpose: "Collects client feedback on the consultation experience.",
      keyComponents: "Questions regarding satisfaction, effectiveness of service, areas for improvement, overall experience rating."
    }
  },
  "Dispute Resolution Document Types": {
    "Non-Disclosure Agreement (NDA)": {
      purpose: "Protects sensitive IP-related information.",
      keyComponents: "Identification of confidential info, obligations of parties, duration, penalties for breach."
    },
    "Copyright Registration Form": {
      purpose: "Establishes legal protection for artistic works.",
      keyComponents: "Title of work, date of creation, author info, type of copyright, declaration of authorship."
    },
    "Trademark Application": {
      purpose: "Applies for protection of brand names and symbols.",
      keyComponents: "Mark representation, goods/services classification, intent to use, owner info."
    },
    "Patent Application": {
      purpose: "Secures exclusive rights to inventions.",
      keyComponents: "Description of invention, claims, drawings, inventor and applicant details."
    },
    "Intellectual Property License Agreement": {
      purpose: "Grants permissions to use intellectual property.",
      keyComponents: "Scope of license, terms of use, royalties, duration, termination conditions."
    },
    "IP Assignment Agreement": {
      purpose: "Transfers ownership of intellectual property.",
      keyComponents: "Description of IP, transferor and transferee details, terms of transfer, warranties regarding ownership."
    },
    "Cease and Desist Letter": {
      purpose: "Requests cessation of alleged illegal IP activity.",
      keyComponents: "Description of IP rights, explanation of infringement, demand for cessation, legal consequences if ignored."
    },
    "IP Audit Checklist": {
      purpose: "Catalogues and assesses IP assets.",
      keyComponents: "List of IP assets, status of each IP, legal protections in place, any licensing agreements."
    }
  }
};

// Custom connectors and icons
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(136deg, #6e5ce0 0%, #3a2bab 50%, #2a1e82 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(136deg, #6e5ce0 0%, #3a2bab 50%, #2a1e82 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#00000000',
  zIndex: 1,
  color: 'wheat',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(136deg, #6e5ce0 0%, #3a2bab 50%, #2a1e82 100%)',
    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, .25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient(136deg, #6e5ce0 0%, #3a2bab 50%, #2a1e82 100%)',
  }),
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-root': {
    backgroundColor: '#1f2937', 
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
  },
  '& .MuiStepLabel-label': {
    color: '#fff',
    fontSize: '0.975rem',
  },
  '& .MuiStepLabel-active': {
    backgroundColor: '#4c6ef5',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
    color: '#f5deb3',
    fontSize: '0.975rem',
  },
  '& .MuiStepLabel-completed': {
    backgroundColor: 'wheat',
    color: '#fff',
    fontSize: '0.975rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <ExtensionIcon />,
    3: <DocumentScannerIcon />,
    4: <FormatListNumberedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

// Wizard component
const Wizard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedSubDocuments, setSelectedSubDocuments] = useState([]);
  const [details, setDetails] = useState({
    purpose: '',
    keyComponents: '',
    additionalDetails: ''
  });
  const [reviewDetails, setReviewDetails] = useState({});
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileDownloaded, setFileDownloaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [showTemplates, setShowTemplates] = useState(false);
  const navigate = useNavigate();

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleNext = () => {
    if (validateStep()) {
      if (activeStep === steps.length - 1) {
        handleSubmit();
      } else {
        if (activeStep === 2) {
          setReviewDetails({
            category: selectedCategory,
            documentType: selectedDocumentType,
            subDocuments: selectedSubDocuments,
            details: details
          });
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setError(null);
        setWarning(null);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError(null);
    setWarning(null);
    setShowTemplates(false);
  };

  const handleGoBackToDocumentDetails = () => {
    setShowTemplates(false);
  }

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedDocumentType(null);
    setSelectedSubDocuments([]);
  };

  const handleDocumentTypeSelect = (docType) => {
    setSelectedDocumentType(docType);
    setSelectedSubDocuments([]);
  };

  const handleSubDocumentSelect = (subDoc) => {
    setSelectedSubDocuments((prev) =>
      prev.includes(subDoc) ? prev.filter((doc) => doc !== subDoc) : [...prev, subDoc]
    );
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const validateStep = () => {
    switch (activeStep) {
      case 0:
        if (!selectedCategory) {
          setError("Please select a category.");
          return false;
        }
        return true;
      case 1:
        if (!selectedDocumentType) {
          setError("Please select a document type.");
          return false;
        }
        return true;
      case 2:
        if (!details.purpose || !details.keyComponents) {
          setError("Please fill out all the details.");
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  const handleSubmit = async () => {
    try {

      setLoading(true);

      const requestData = {
        category: selectedCategory,
        documentType: selectedDocumentType,
        subDocuments: selectedSubDocuments,
        details: details
      };

      //const endpoint = `http://docmgmtclustlb-751564006.us-east-1.elb.amazonaws.com/api/documents/documents/generatedoc?prompt=${JSON.stringify(requestData)}`;
      const data = reviewDetails.category + ", " + requestData.documentType + ", " + requestData.subDocuments.join(',') + (reviewDetails.details.purpose && ", " + reviewDetails.details.purpose) + (reviewDetails.details.keyComponents && ", " + reviewDetails.details.keyComponents) + (reviewDetails.details.additionalDetails && ", " + reviewDetails.details.additionalDetails);
      console.log(data);
      //const response = await FolderService.generateDocument(data);
      const [response] = await Promise.all([
        FolderService.generateDocument(data),
        new Promise((resolve) => setTimeout(resolve, 5000))
      ]);

      const presignedUrl = response.data;
      showSnackbar('Document generated successfully! Redirecting to the preview...', 'success');

      await Promise.all([
        new Promise((resolve) => setTimeout(resolve, 5000))
      ]);

      setTimeout(() => {
        navigate(`/summarize-doc?url=${encodeURIComponent(presignedUrl)}`);
      }, 3000);

      setFileDownloaded(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError('An error occurred while generating the document.');
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setSelectedCategory(null);
    setSelectedDocumentType(null);
    setSelectedSubDocuments([]);
    setDetails({
      purpose: '',
      keyComponents: '',
      additionalDetails: ''
    });
    setReviewDetails({});
    setError(null);
    setWarning(null);
    setLoading(false);
    setFileDownloaded(false);
  };

  const cardStyles = {
    common: {
      borderRadius: '10px',
      width: '275px',
      height: '275px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
    },
    selected: {
      border: '2px solid white',
    },
    hover: {
      '&:hover': {
        filter: 'brightness(1.1)',
      },
    },
  };

  const iconContainerStyles = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: '2px solid white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  };

  const cardContentStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  };

  const DocumentCard = ({ title, icon, isSelected, onClick, backgroundColor }) => (
    <Grid item xs={12} sm={6} md={3}>
      <Card
        onClick={onClick}
        style={{
          ...cardStyles.common,
          backgroundColor,
          border: isSelected ? cardStyles.selected.border : 'none',
        }}
        sx={cardStyles.hover}
      >
        <CardContent style={cardContentStyles}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <div style={iconContainerStyles}>
              {icon || <InsertDriveFileRounded style={{ color: 'white', fontSize: 70 }} />}
            </div>
          
          <ListItemText primary={title} primaryTypographyProps={{ style: { color: 'white' } }} />
          </div>
        </CardContent>
      </Card>
    </Grid>
  );


  const [docColors, setDocColors] = useState({});
  const [categoryColors, setCategoryColors] = useState({});

  const getRandomColorWithinRange = (color1, color2) => {
    const result = color1.slice(1).match(/.{1,2}/g).map((v, i) =>
      Math.round(parseInt(v, 16) + Math.random() * (parseInt(color2.slice(1).match(/.{1,2}/g)[i], 16) - parseInt(v, 16)))
    );
    return `#${result.map(v => v.toString(16).padStart(2, '0')).join('')}`;
  };

  const generateUniqueColors = (items) => {
    const colors = new Set();
    const uniqueColors = {};

    items.forEach(item => {
      let color;
      do {
        color = getRandomColorWithinRange('#75344f', '#395fa5');
      } while (colors.has(color));
      colors.add(color);
      uniqueColors[item] = color;
    });

    return uniqueColors;
  };

  useEffect(() => {
    const categories = Object.keys(data);
    setCategoryColors(generateUniqueColors(categories));
  }, [data]);

  useEffect(() => {
    if (selectedCategory) {
      const documentOptions = data[selectedCategory] || [];
      const newDocColors = generateUniqueColors(Array.isArray(documentOptions) ? documentOptions : Object.keys(documentOptions));
      setDocColors(newDocColors);
    }
  }, [data, selectedCategory]);
  
  
  const handleSelectTemplateClick = () => {
    setShowTemplates(true);
  };

  const handleTemplateDownload = (document) => {
    // Logic to download the document
    console.log(`Downloading document: ${document}`);
  };


  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Select the type of document you want to generate</Typography>
            <Grid container spacing={2}>
              {Object.keys(data).map((category) => {
                const isSelected = selectedCategory === category;
                const randomColor = categoryColors[category];
                return (
                  <DocumentCard
                    key={category}
                    title={category}
                    icon={iconMapping[category]}
                    isSelected={isSelected}
                    onClick={() => handleCategorySelect(category)}
                    backgroundColor={randomColor}
                  />
                );
              })}
            </Grid>
          </Box>
        );

      case 1:
        if (!selectedCategory) {
          return <Alert severity="warning">Please select a document category first.</Alert>;
        }

        const documentOptions = data[selectedCategory];

        return (
          <Box>
            <Typography variant="h6" gutterBottom>Select Document Type</Typography>
            <Grid container spacing={2}>
              {Array.isArray(documentOptions) ? (
                documentOptions.map((docType) => (
                  <DocumentCard
                    key={docType}
                    title={docType}
                    icon={iconMapping[docType]}
                    isSelected={selectedDocumentType === docType}
                    onClick={() => handleDocumentTypeSelect(docType)}
                    backgroundColor={docColors[docType] || 'transparent'}
                  />
                ))
              ) : (
                Object.keys(documentOptions).map((docType) => (
                  <DocumentCard
                    key={docType}
                    title={docType}
                    icon={iconMapping[docType]}
                    isSelected={selectedDocumentType === docType}
                    onClick={() => handleDocumentTypeSelect(docType)}
                    backgroundColor={docColors[docType] || 'transparent'}
                  />
                ))
              )}
            </Grid>
          </Box>
        );
      case 2:
        const selectedDocDetails = data[selectedCategory][selectedDocumentType];
        const subDocumentOptions = Object.keys(selectedDocDetails || {});
        if (showTemplates) {
          const documentOptions = data[selectedCategory];
          return (
            <Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGoBackToDocumentDetails}
                  sx={{ mt: 2 }}
                >
                  Go back
                </Button>
              </Box>
              <Typography variant="h6" gutterBottom>Select a Template</Typography>
              <Grid container spacing={3}>
                {documentOptions.map((document) => (
                  <Grid item xs={12} sm={6} md={4} key={document.name}>
                    <Card>
                      <CardMedia
                        component="img"
                        height="140"
                        image={document.thumbnail} // Replace with the actual thumbnail URL
                        alt={document.name}
                      />
                      <CardContent>
                        <Typography variant="h6">{document.name}</Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleTemplateDownload(document.name)}
                        >
                          Download
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          );
        }
        
        if (selectedDocumentType && (typeof data[selectedCategory][selectedDocumentType] === 'object') || Array.isArray(data[selectedCategory])) {
          const docDetails = data[selectedCategory][selectedDocumentType];
          return (
            <Box sx={{}}>
              {/* <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSelectTemplateClick}
                  sx={{ mt: 2 }}
                >
                  Select Template
                </Button>
              </Box> */}
              <Typography variant="h6" gutterBottom>Provide few details about the document you want to generate</Typography>
              {/* <Typography variant="h6" gutterBottom>Details</Typography> */}
              <Box sx={{ p: 2, background: 'white', borderRadius: '5px' }}>
                <TextField
                  label="Purpose"
                  name="purpose"
                  focused
                  placeholder='Purpose of this document'
                  multiline
                  rows={2}
                  fullWidth
                  variant="outlined"
                  value={details.purpose}
                  onChange={handleDetailsChange}
                  sx={{ mb: 3 }}
                />

                <TextField
                  label="Key Components"
                  name="keyComponents"
                  focused
                  placeholder='Key components of this document'
                  multiline
                  rows={2}
                  fullWidth
                  variant="outlined"
                  value={details.keyComponents}
                  onChange={handleDetailsChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Additional Details"
                  name="additionalDetails"
                  focused
                  placeholder='Any additional details'
                  multiline
                  rows={3}
                  fullWidth
                  variant="outlined"
                  value={details.additionalDetails}
                  onChange={handleDetailsChange}
                />
              </Box>
            </Box>
          );
        }
        return null;
      case 3:
        return (
          <Box sx={{}}>
            <Typography variant="h6" gutterBottom>Review the details before submitting</Typography>
            <List>
              <CustomListItemSummary divider>
                <ListItemText primary="Document Type" secondaryTypographyProps={{ color: 'white' }} secondary={reviewDetails.category} />
              </CustomListItemSummary>
              <CustomListItemSummary divider>
                <ListItemText primary="Document" secondaryTypographyProps={{ color: 'white' }} secondary={reviewDetails.documentType} />
              </CustomListItemSummary>
              {reviewDetails.subDocuments.length > 0 && (
                <CustomListItemSummary divider>
                  <ListItemText primary="Sub Documents" secondaryTypographyProps={{ color: 'white' }} secondary={reviewDetails.subDocuments.join(', ')} />
                </CustomListItemSummary>
              )}
              {reviewDetails.details.purpose && (
                <CustomListItemSummary divider>
                  <ListItemText primary="Purpose" secondaryTypographyProps={{ color: 'white' }} secondary={reviewDetails.details.purpose} />
                </CustomListItemSummary>
              )}
              {reviewDetails.details.keyComponents && (
                <CustomListItemSummary divider>
                  <ListItemText primary="Key Components" secondaryTypographyProps={{ color: 'white' }} secondary={reviewDetails.details.keyComponents} />
                </CustomListItemSummary>
              )}
              {reviewDetails.details.additionalDetails && (
                <CustomListItemSummary divider>
                  <ListItemText primary="Additional Details" secondaryTypographyProps={{ color: 'white' }} secondary={reviewDetails.details.additionalDetails} />
                </CustomListItemSummary>
              )}
            </List>
            {loading && <Typography sx={{ mt: 2 }} style={{
              borderRadius: "5px",
              margin: "5px 0",
              padding: "10px 15px",
              backgroundColor: '#c5dde33b'
            }} variant="body2"> <CircularProgress size={24} color='inherit'/> Document is being generated...</Typography>}
            {fileDownloaded && <Alert sx={{ mt: 2 }} severity="success">Document generated successfully! Redirecting to the preview...</Alert>}
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    // <div style={{ width: '80%', margin: 'auto' }}>
    <CustomBox sx={{ width: '100%', mx: 'auto', paddingTop: 0 }}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center" // Align items vertically centered
      >
        <IconButton style={{ margin: '0 0 0px 0px', width: '40px', height: '40px', background: 'white' }} onClick={() => navigate(-1)} edge="start" aria-label="back">
          <Tooltip title="back"><ArrowBack /></Tooltip>
        </IconButton>
        <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center', padding: '0px 10px 0px 10px' }} className='text-light'>
          Document Generator
          {/* <IconButton style={{width:'40px', height:'40px'}} onClick={handleInformationDialogOpen} edge="start" color="inherit" aria-label="info">
              <Info style={{ marginLeft: '5px' }} />
              </IconButton> */}
        </Typography>
      </Box>
      <Stepper sx={{ mt: 2 }} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StyledStepLabel StepIconComponent={ColorlibStepIcon}>{label}</StyledStepLabel>
          </Step>
        ))}
      </Stepper>
      {/* <Paper elevation={3} sx={{ p: 3, mt: 2 }}> */}
      <CustomContentBox sx={{ mt: 3, p: 3 }}>
      <Box>
        <Typography>
        {selectedCategory && (
          <>
            {selectedCategory} 
            {selectedDocumentType && (
              <>
                <ArrowRight sx={{ mx: 1 }} /> 
                {selectedDocumentType}
              </>
            )}
          </>
        )}
        </Typography>
      </Box>
        {getStepContent(activeStep)}
        {error && <Alert severity="error">{error}</Alert>}
        {warning && <Alert severity="warning">{warning}</Alert>}
        {/* <div style={{ marginTop: '20px' }}> */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <StyledButton hidden={activeStep === 0} onClick={handleBack} style={{ marginRight: '10px', maxWidth: '150px' }}
            variant="contained"
            sx={{ background: '#4a0f73' }}
            startIcon={<ArrowLeftSharp />}>
            Back
          </StyledButton>
          {activeStep < steps.length - 1 && (
            <StyledButton
              variant="contained"
              endIcon={<ArrowRightSharp />}
              onClick={handleNext}
              disabled={loading}
              style={{ maxWidth: '150px' }}
              sx={{ background: '#4a0f73', justifySelf: 'flex-end' }}
            >
              {activeStep === steps.length - 2 ? "Review" : "Next"}
            </StyledButton>
          )}

          {activeStep === 3 && !fileDownloaded && (
            <>{loading ? 
              <StyledButton
              startIcon={<CircularProgress size={24} color='inherit' />}
                variant="contained"
                onClick={handleNext}
                disabled={loading}
                style={{ maxWidth: '150px', color:'white' }}
                sx={{ background: '#4a0f73' }}
              >
                Submitting...
              </StyledButton> : 

              <StyledButton
                variant="contained"
                onClick={handleNext}
                disabled={loading}
                style={{ maxWidth: '150px' }}
                sx={{ background: '#4a0f73' }}
              >
                Submit
              </StyledButton>}
            </>
          )}
          {fileDownloaded && activeStep === 3 && (
            <StyledButton
              variant="contained"
              onClick={handleReset}
              style={{ marginLeft: '10px', maxWidth: '250px' }}
              sx={{ background: '#4a0f73' }}
            >
              Generate Another Document
            </StyledButton>
          )}
        </Box>
      </CustomContentBox>
      <CustomSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}

      />
    </CustomBox>
  );
};

export default Wizard;
