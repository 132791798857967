import { Box, Dialog, Modal } from '@mui/material';
import React, { useState } from 'react';

const RenameFolderPopup = ({ isOpen, onClose, onRename }) => {
    const [folderName, setFolderName] = useState('');

    const handleRename = () => {
        onRename(folderName);
        setFolderName('');
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Modal open={isOpen} className="popup">
            <Box className="popup-content">
                <h3>Rename Folder</h3>
                <input 
                    type="text" 
                    value={folderName} 
                    onChange={(e) => setFolderName(e.target.value)} 
                    placeholder="Enter new folder name" 
                />
                <button onClick={handleRename}>Rename</button>
                <button onClick={onClose}>Cancel</button>
            </Box>
        </Modal>
    );
};

export default RenameFolderPopup;