import axios from "axios";
import { USERMANAGEMENT_API_URL } from "./Endpoints";
const API_URL = USERMANAGEMENT_API_URL
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'content-type': 'application/json'
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const setAuthToken = (token = null) => {
    console.log('Setting auth token: ' + token);
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

export default axiosInstance;