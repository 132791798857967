// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal-docs-pro-header {
    font-size: 2.5rem; /* Large font size for visibility */
    color: wheat; /* White color for contrast */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Text shadow for depth */
    margin: 0 0 10px 0; /* Spacing below the header */
    text-align: center; /* Center-align text */
  }
  
  .subtitle {
    font-size: 24px; /* Smaller than the title but still significant */
    color: #EDEDED; /* Light grey for a softer appearance */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Subtle text shadow for readability */
    margin-bottom: 20px; /* Extra space below the subtitle */
    text-align: center; /* Center-align text */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/InfoPanel.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB,EAAE,mCAAmC;IACtD,YAAY,EAAE,6BAA6B;IAC3C,2CAA2C,EAAE,0BAA0B;IACvE,kBAAkB,EAAE,6BAA6B;IACjD,kBAAkB,EAAE,sBAAsB;EAC5C;;EAEA;IACE,eAAe,EAAE,iDAAiD;IAClE,cAAc,EAAE,uCAAuC;IACvD,2CAA2C,EAAE,uCAAuC;IACpF,mBAAmB,EAAE,mCAAmC;IACxD,kBAAkB,EAAE,sBAAsB;EAC5C","sourcesContent":[".legal-docs-pro-header {\n    font-size: 2.5rem; /* Large font size for visibility */\n    color: wheat; /* White color for contrast */\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Text shadow for depth */\n    margin: 0 0 10px 0; /* Spacing below the header */\n    text-align: center; /* Center-align text */\n  }\n  \n  .subtitle {\n    font-size: 24px; /* Smaller than the title but still significant */\n    color: #EDEDED; /* Light grey for a softer appearance */\n    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Subtle text shadow for readability */\n    margin-bottom: 20px; /* Extra space below the subtitle */\n    text-align: center; /* Center-align text */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
