// src/UserProfile.js
import { Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { useAuth } from '../Services/Authentication/AuthContext';
import './UserProfile.css'
import { stringAvatar } from '../Components/AvatarHelper';
const UserProfile = () => {

  const { userProfile, error } = useAuth();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // Mock data for user details and documents
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: ''
  });
  useEffect(() => {
    const init = async () => {
      try {
        const currentUser = await userProfile();
        console.log(currentUser);

        
        setUsername(currentUser.username);
        setEmail(currentUser.email)
        //currentUser.firstName = 'Test';
        //currentUser.lastName = 'Update';
        setFormData(currentUser);

      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  const [documents, setDocuments] = useState([
    { id: 1, title: "Service Agreement", date: "2022-07-01" },
    { id: 2, title: "NDA Agreement", date: "2022-06-20" },
    { id: 3, title: "Lease Agreement", date: "2022-06-15" }
  ]);

  const handleDeleteDocument = (docId) => {
    // Placeholder function to simulate deleting a document
    setDocuments(prevDocs => prevDocs.filter(doc => doc.id !== docId));
  };

  const [isEditable, setIsEditable] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstName:'',
    lastName:''
  });

  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleSave = () => {
    setIsEditable(false);
    // Add your save logic here, e.g., make an API call to save the data
    console.log(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Container className='user-profile text-light rounded '>
      <Row style={{ display: 'flex' }} className="text-light px-3 py-2 radius-card">
        <h3>User Profile</h3>
      </Row>
      <Row className='p-3'>
        <Col md={4} className="md-4 border-right">
          <div className="d-flex flex-column align-items-center text-center p-3">
            <Avatar className="rounded-circle mt-5" style={{ width: 175, height: 175, fontSize: 75 }} {...stringAvatar(formData.firstName + ' ' + formData.lastName)}></Avatar>
            <span class="font-weight-bold">{formData.username}</span>
            <span class="font-weight-bold">{formData.email}</span><span> </span></div>
        </Col>

        <Col md={8} className="md-8 border-right py-10" style={{ display: 'flex' }}>
          <Form className='justify-content-start' style={{ width: 400, maxWidth: '100%' }}>
            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="mb-3" controlId="formFirstName">
                  <Form.Label sm="2">First Name</Form.Label>
                  <Col sm>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      readOnly={!isEditable}
                      disabled={!isEditable}
                    />
                  </Col>
                </Form.Group>


              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="mb-3" controlId="formLastName">
                  <Form.Label sm="2">Last Name</Form.Label>
                  <Col sm>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      readOnly={!isEditable}
                      disabled={!isEditable}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group as={Row} className="mb-3" controlId="formUsername">
              <Form.Label sm="2">Username</Form.Label>
              <Col sm>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  readOnly
                  disabled
                />
              </Col>
            </Form.Group>


            <Form.Group as={Row} className="mb-3" controlId="formEmail">
              <Form.Label sm="2">Email Address</Form.Label>
              <Col>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  readOnly
                  disabled
                />
              </Col>
            </Form.Group>

            <Button style={{ maxWidth: '150px' }} onClick={isEditable ? handleSave : handleEdit}>
              {isEditable ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-floppy" viewBox="0 0 20 20">
                <path d="M11 2H9v3h2z" />
                <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
              </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 20 20">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
              </svg>}
              {isEditable ? 'Update' : 'Edit'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
