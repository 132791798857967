import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  styled,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Navigate } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";

const plans = [
  {
    id:'0',
    title: "Basic Plan (Free)",
    amount:'0',
    price: "0/month",
    features: [
      "5 MB file size limit",
      "100 files upload per month",
      "3 files upload per day",
      "100 file limit per upload",
      "2 documents summarized per day",
      "2 legal queries per day",
      "Access to basic legal document templates",
      "Community support forums",
    ],
    buttonText: "Start Free",
    buttonColor: "#198754", // Green
    hoverColor: "#155d27", // Darker Green
  },
  {
    id:'1',
    title: "Professional Plan",
    amount:'1900',
    price: "19/month",
    features: [
      "25 MB file size limit",
      "500 files upload per month",
      "16 files upload per day",
      "300 file limit per upload",
      "10 documents summarized per day",
      "10 legal queries per day",
      "300 question credits for legal queries per month",
      "Email support",
      "Access to advanced document templates",
    ],
    buttonText: "Choose Professional",
    buttonColor: "#007BFF", 
    hoverColor: "#0056b3",
  },
  {
    id:'2',
    title: "Advanced Plan",
    amount:'3900',
    price: "39/month",
    features: [
      "50 MB file size limit",
      "2,000 files upload per month",
      "66 files upload per day",
      "500 file limit per upload",
      "30 documents summarized per day",
      "30 legal queries per day",
      "1,000 question credits for legal queries per month",
      "Priority email and chat support",
      "Document comparison and version control features",
    ],
    buttonText: "Choose Advanced",
    buttonColor: "#DC3545", // Red
    hoverColor: "#c82333", // Darker Red
  },
  {
    id:'3',
    amount:'00.00',
    title: "Enterprise Plan",
    price: "Custom Pricing",
    features: [
      "Custom file size limits",
      "Custom files and uploads per month",
      "Custom files upload per day",
      "Custom file limits per upload",
      "Custom documents summarized per day",
      "Custom legal queries per day",
      "Unlimited question credits for legal queries",
      "Dedicated account manager and phone support",
      "Integration with existing enterprise systems (CRM, ERP)",
      "Advanced data security and compliance features",
    ],
    buttonText: "Contact Us",
    buttonColor: "#28a745", // Dark Green
    hoverColor: "#218838", // Darker Green
  },
];

// Define a styled button component
const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: 'calc(6px + 2vmin)',
    borderRadius: '30px',
    height: '55px',
    width: '200px',
  }));
  
  const stripePromise = loadStripe("pk_test_51QSr6AH2knkYL7mJEqxfcIEk27kqqVMEnQfJfsjN5sZQrr8vr2XoXs56r8xK2IOJ3QgzgAPzwLnAUx2YtwxebkfF00HmM4gJ3F");
  const handlePayment = async (planId, title, amount) => {

    if (planId === 0 || planId === 3) {
      Navigate('/auth');
      return;
    }

    const stripe = await stripePromise;
  
    try {
      const response = await axiosInstance.post("http://localhost:8089/api/documents/documents/create-checkout-session", {
        planId : title,
        amount,
      });
  
      const { id } = response.data;
  
      stripe.redirectToCheckout({ sessionId: id });
    } catch (error) {
      console.error("Payment error:", error);
      alert("Payment initialization failed. Please try again.");
    }
  };

  const Plans = () => {
    return (
      <Container maxWidth="lg" style={{
        padding: "2rem 2rem",
        background: "linear-gradient(to top, rgba(113, 29, 125, 0.2), rgba(121, 91, 209, 0.2))",
        borderRadius: "10px",
        boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.1)",
      }}>
        <Typography variant="h1" align="center" gutterBottom sx={{ color: 'white' }}>
          Choose Your Plan
        </Typography>
        <Grid container spacing={1}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                }}
                elevation={3}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow = "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.1)";
                }}
              >
                <div
                  style={{
                    background: "linear-gradient(135deg, rgba(113,29,125,0.8), rgba(121,91,209,0.8))",
                    padding: "1rem",
                    textAlign: "center",
                    borderRadius: "8px 8px 0 0", // Rounded corners at the top
                  }}
                >
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight:400,
                      color: "#ffffff",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {plan.title}
                  </Typography>
                  <Typography
                    variant="h3"
                    style={{
                      color: "#ffffff",
                      fontSize: "1.1rem",  // Smaller font size for the price
                      position: "relative",
                      top: "5px",  // Position it slightly upwards like subtext
                    }}
                  >
                     {plan.price !== 'Custom Pricing' ? '$' : ''}{plan.price} 
                  </Typography>
                </div>
                {plan.title !== 'Basic Plan (Free)' ? (
                  <div
                    style={{
                      backgroundColor: "#ff0000", // Red background
                      color: "#ffffff", // White font
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                  Coming Soon
                </div>): (<></>)}
                <CardContent style={{ flexGrow: 1 }}>
                
                  <List
                    dense
                    sx={{
                      paddingLeft: 0,
                      textAlign: 'center',
                      lineHeight: 1.6,
                      fontSize: "1rem",
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    {plan.features.map((feature, idx) => (
                      <ListItem
                        key={idx}
                        disableGutters
                        sx={{
                          padding: '4px 0', // Reduced padding for less space
                          borderBottom: '1px solid #ddd', // Line separator between items
                          display: 'list-item',
                        }}
                      >
                        <ListItemText
                          primary={feature}
                          primaryTypographyProps={{
                            variant: "body1",
                            sx: { textAlign: 'center' }, // Ensure the text is centered
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <CardActions style={{ justifyContent: "center", marginTop: "auto" }}>
                  <Button
                    variant={plan.buttonVariant}
                    size="large"
                    style={{
                      borderRadius: "20px",
                      padding: "0.5rem 2rem",
                      backgroundColor: "#198754",
                      color: "#fff",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                      transition: "all 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#fff";
                      e.target.style.color = "#4a0f73";
                      e.target.style.boxShadow = "0px 6px 15px rgba(0, 0, 0, 0.3)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#198754";
                      e.target.style.color = "#fff";
                      e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.2)";
                    }}
                    // onClick={() => handlePayment(plan.id, plan.title, plan.amount)}
                  >
                    {plan.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };

export default Plans;
