// src/UserProfile.js
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from '../Services/Authentication/AuthContext';

const ChangePassowrd = () => {

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const { updatePassword, error } = useAuth();
  
    useEffect(() => {
        const init = async () => {
            try {

            } catch (error) {
                console.error(error);
            }
        };
        init();
    }, []);

    const [formData, setFormData] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    });

    const handleSave = async (e) => {
        // Add your save logic here, e.g., make an API call to save the data
        console.log(formData);
        e.preventDefault();
        setMessage('');
        
        if (newPassword !== confirmPassword) {
            setMessage('New passwords do not match');
            return;
        }

        try {
            // Replace with your API endpoint
            const response = await updatePassword(password,newPassword);
      
            if (response.status === 200) {
              setMessage('Password updated successfully');
            } else {
                setMessage('Failed to update password');
            }
          } catch (error) {
            setMessage('Failed to update password');
          }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Container className='user-profile text-light rounded '>
            <Row style={{ display: 'flex' }} className="text-light px-3 py-2 radius-card">
                <h3>Change Password</h3>
            </Row>
            <Row className='p-3'>
                <Col md={8} className="md-8 border-right py-10" style={{ display: 'flex' }}>
                
                    {error && <Alert severity="error">{error}</Alert>}
                    <Form noValidate onSubmit={handleSave} validated={validated} className='justify-content-start' style={{ width: 400, maxWidth: '100%' }}>
                    {message && <Alert variant="outlined" severity="error">{message}</Alert>}
                        <Form.Group as={Row} className="mb-3" controlId="formCurrentPassword">
                            <Form.Label sm="2">Current Password</Form.Label>
                            <Col>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    isInvalid={
                                        validated && formData.password.length < 1
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                Current password required.
                            </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formNewPassword">
                            <Form.Label sm="2">New Password</Form.Label>
                            <Col>
                                <Form.Control
                                    type="password"
                                    name="newPassword"
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                    minLength={6}
                                    required
                                    isInvalid={
                                        validated && formData.newPassword.length < 6
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Password must be at least 6 characters long.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formConfirmPassword">
                            <Form.Label sm="2">Confirm New Password</Form.Label>
                            <Col>
                                <Form.Control
                                    type="password"
                                    name="confirmNewPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    minLength={6}
                                    required
                                    isInvalid={
                                        validated &&
                                        formData.confirmPassword !== formData.newPassword
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                Passwords do not match.
                            </Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Button style={{ maxWidth: '150px' }} onClick={handleSave}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-floppy" viewBox="0 0 20 20">
                                <path d="M11 2H9v3h2z" />
                                <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                            </svg>
                            Update
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ChangePassowrd;
