import React from 'react';
import './SearchCard.css';
import docImage from '../images/docs.jpeg'; // Ensure this image is optimized for web
import { Container, Row, Col, Button } from 'react-bootstrap';
import StyledButton from './Folders/StyledButton';
import { useNavigate } from 'react-router-dom';
import { ArrowRightAltRounded } from '@mui/icons-material';

const features = [
  {
    id: 1,
    image: docImage,
    alt: "AI-powered legal services",
    title: "AI-Powered Legal Assistant",
    description: "Experience unmatched efficiency and precision with our AI-powered legal assistant. Get real-time help with legal queries, ensuring accurate advice when you need it most.",
  },
  {
    id: 2,
    image: docImage,
    alt: "Document Generation and Management",
    title: "Document Generation and Management",
    description: "Simplify your legal processes with our advanced document generator. Create, customize, and manage essential legal documents such as contracts and agreements with ease.",
  },
  {
    id: 3,
    image: docImage,
    alt: "Connect with Top Lawyers",
    title: "Connect with Top Lawyers",
    description: "Need expert legal advice? Our platform connects you to top lawyers near you, providing professional support tailored to your specific legal needs.",
  },
];

const SearchCard = () => {

  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/legal-query-assistant');
  }

  return (
    <Container fluid className="landing-page-container">
      <Row className="hero-section text-center align-items-center">

        <Col>
          <h1 className="info-panel" style={{ color: 'wheat' }}>Revolutionizing Legal Services with</h1>
          <div className="info-panel">
            <h1 className="legal-docs-pro-header">Olanur</h1>
            <p className="subtitle">Discover the Power of AI in Legal Services with Olanur</p>
            <p className="hero-subtitle">Get instant access to powerful legal tools and expert advice, powered by artificial intelligence.</p>
            {/* Include any other elements or components as needed */}
          </div>
          {/* <h1 className="hero-title">Revolutionizing Legal Services with AI</h1> */}
          {/* <p className="hero-subtitle">Get instant access to powerful legal tools and expert advice, powered by artificial intelligence.</p> */}
          <StyledButton onClick={handleGetStarted} variant="contained" sx={{  fontSize:'calc(6px + 2vmin);', borderRadius:'30px', height: '55px', width:'200px', background: '#198754' }} className="hero-button">Get Started</StyledButton>
        </Col>
      </Row>

      {/* <Row className="features-section">
        {features.map(feature => (
          <Col md={4} key={feature.id} className="feature-card text-center">
            <img src={feature.image} alt={feature.alt} className="feature-image" />
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </Col>
        ))}
      </Row>

      <Row className="cta-section text-center">
        <Col>
          <h2 className="cta-title">Ready to Simplify Your Legal Work?</h2>
          <p className="cta-description">Join thousands of professionals who rely on our AI-powered platform for fast, secure, and effective legal services.</p>
          <Button variant="success" className="cta-button">Sign Up Now</Button>
        </Col>
      </Row> */}
    </Container>
  );
};

export default SearchCard;
