// src/UserProfile.js
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from '../Services/Authentication/AuthContext';
import InfoPanel from '../Components/InfoPanel';

const ResetPasswordRequest = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const { requestPasswordReset, error } = useAuth();

    useEffect(() => {
        const init = async () => {
            try {

            } catch (error) {
                console.error(error);
            }
        };
        init();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        try {
            const response = await requestPasswordReset(email);
            setMessage('Password reset link has been sent to your email.');
        } catch (error) {
            setMessage('Failed to send password reset link');
        }
    };

    return (

        <Container style={{ display: 'flex' }} className='user-profile text-light rounded '>
            <Row>
                <InfoPanel />
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} className="text-light px-3 py-2 radius-card">
                <h3>Request Password Reset</h3>
            </Row>
            <Row className='p-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {message && <Alert severity="success">{message}</Alert>}
                {error && <Alert severity="error">{error}</Alert>}
                <Form noValidate onSubmit={handleSubmit} validated={validated} style={{ width: 750, maxWidth: '100%', justifyContent: 'center'}}>
                    <Col className="py-10" style={{ justifyContent: 'center'}}>
                        <Form.Group controlId="email">
                            {/* <Form.Label>Your professional email</Form.Label> */}
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder='Your professional email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                isInvalid={
                                    validated &&
                                    !/^\S+@\S+\.\S+$/.test(email)
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid email address.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button style={{ maxWidth: '150px' }} type='submit'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 20 20">
                                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                            </svg>
                            Submit
                        </Button>

                    </Col>
                </Form>
            </Row>
        </Container>
    );
};

export default ResetPasswordRequest;
