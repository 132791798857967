import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Row, Col, Card, Form, Button, ProgressBar } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './LegalAssistant.css'
import Wizard from './Wizard';
import WizardNew from './WizardM';

function LegalAssistant() {
    const [documentType, setDocumentType] = useState('');
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState('');
    const { register, handleSubmit } = useForm();


    const schema = yup.object().shape({
        service: yup.string().required('Service is required'),
        documentType: yup.string().required('Document type is required'),
        document: yup.string().required('Document is required'),
        details: yup.string().required('Details are required'),
    });

    const steps = [
        { title: 'Service Selection' },
        { title: 'Document Type' },
        { title: 'Document Selection' },
        { title: 'Enter Details' },
        { title: 'Review & Submit' }
    ];

    const methods = useForm({
        resolver: yupResolver(schema)
    });
    const { formState: { errors }, watch } = methods;

    const [step, setStep] = useState(0);

    const onNext = data => {
        if (step < steps.length - 1) {
            setStep(prevStep => prevStep + 1);
        } else {
            console.log(data);
            // submit the form data
        }
    };

    return (
        <div className="legal-assistant" style={{ backgroundColor: 'transparent' }}>
            <Container fluid>
                <Card style={{background:'transparent', border:'none'}}>
                    <WizardNew />
                </Card>
            </Container>
        </div>
    );

}

export default LegalAssistant;