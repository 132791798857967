import React from 'react';

const AppTitle = (props) => {
    return (
      <>
        LegalBuddy
      </>
    );
  };
  
  export default AppTitle;