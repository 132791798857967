import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, InputGroup, Row, Col, Spinner, Modal } from 'react-bootstrap';
import { Alert, Snackbar } from '@mui/material';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import axios from 'axios';

import './Signup.css';

function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  // State for the Terms of Use checkbox
  const [termsChecked, setTermsChecked] = useState(false);

  // State for controlling Terms of Use Modal
  const [showTermsModal, setShowTermsModal] = useState(false);

  // Master form state
  // Note that we default role to "lookingForLawyer"
  const [authForm, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    role: 'lookingForLawyer',  // <-- Default to "Looking for a lawyer"
    barNumber: '',
    jurisdiction: '',
    practiceAreas: '',
    paralegalCertificate: '',
    paralegalAssociation: '',
    lawSchool: '',
    graduationYear: '',
    supportingDocuments: null,
    // Extra fields not strictly needed by your API
    address: '',
    zipCode: '',
    lawFirm: '',
    yearsOfPractice: '',
    paralegalLawFirm: '',
    studentID: '',
    paralegalCertFile: null,
    enrollmentProof: null,
  });

  const navigate = useNavigate();

  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword(prev => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(prev => !prev);

  // Handle input changes in the sign-up form
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // If we're dealing with file input, use files[0]
    if (files) {
      setFormData({ ...authForm, [name]: files[0] });
    } else {
      setFormData({ ...authForm, [name]: value });
    }
  };

  // Open Terms Modal
  const handleOpenTerms = (e) => {
    e.preventDefault();
    setShowTermsModal(true);
  };

  // Close Terms Modal
  const handleCloseTerms = () => {
    setShowTermsModal(false);
  };

  // Handle form submission
  const handleSignup = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    // Ensure the Terms of Use checkbox is checked
    if (!termsChecked) {
      setMessage('You must agree to the Terms of Use before signing up.');
      setShowSnackbar(true);
      return;
    }

    setIsLoading(true);

    try {
      // Construct the payload to match the API structure
      const payload = {
        username: authForm.username,
        email: authForm.email,
        password: authForm.password,
        firstName: authForm.firstName,
        lastName: authForm.lastName,
        // Convert the internal role to the string your API expects
        role: authForm.role === 'lookingForLawyer' ? 'Looking for a lawyer' : authForm.role,
        barNumber: authForm.barNumber,
        jurisdiction: authForm.jurisdiction,
        practiceAreas: authForm.practiceAreas,
        paralegalCertificate: authForm.paralegalCertificate,
        paralegalAssociation: authForm.paralegalAssociation,
        lawSchool: authForm.lawSchool,
        expectedGraduationYear: authForm.graduationYear?.toString() || '',
        supportingDocuments: authForm.supportingDocuments
          ? authForm.supportingDocuments.name || 'filename.ext'
          : '',
      };

      // POST to your AWS API endpoint
      const response = await axios.post(
        'https://mtt2dp3ze6.execute-api.us-east-1.amazonaws.com/dev/registration',
        payload
      );

      // Assuming a successful 2xx response
      setMessage(
        'Registration successful! A confirmation email has been sent to you. ' +
          'Please click on it to activate your account.' +
          (response.data?.message || '')
      );
      setShowSnackbar(true);

      // Optional: navigate after a delay
      setTimeout(() => {
        navigate('/');
      }, 10000);
    } catch (error) {
      console.error(error);
      setMessage(
        'Registration failed: ' +
          (error.response?.data?.message || error.message || 'An error occurred')
      );
      setShowSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSignup}>
        <div className='text-center text-light'>
          <h4 className='py-2 radius-card'>Sign Up</h4>
        </div>

        {/* First & Last Name */}
        <Row className='gx-1'>
          <Col md={6}>
            <Form.Group controlId='firstName'>
              <Form.Control
                type='text'
                name='firstName'
                placeholder='First Name'
                value={authForm.firstName}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type='invalid' style={{ background: 'red', color: 'white' }}>
                Please enter your first name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId='lastName'>
              <Form.Control
                type='text'
                name='lastName'
                placeholder='Last Name'
                value={authForm.lastName}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type='invalid' style={{ background: 'red', color: 'white' }}>
                Please enter your last name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Username */}
        <Form.Group controlId='username'>
          <Form.Control
            placeholder='Username'
            type='text'
            name='username'
            value={authForm.username}
            onChange={handleChange}
            pattern='^[a-zA-Z0-9]+$'
            required
          />
          <Form.Control.Feedback type='invalid' style={{ background: 'red', color: 'white' }}>
            Please enter a valid username (letters and numbers only).
          </Form.Control.Feedback>
        </Form.Group>

        {/* Email */}
        <Form.Group controlId='email'>
          <Form.Control
            type='email'
            name='email'
            placeholder='Your professional email'
            value={authForm.email}
            onChange={handleChange}
            required
          />
          <Form.Control.Feedback type='invalid' style={{ background: 'red', color: 'white' }}>
            Please enter a valid email address.
          </Form.Control.Feedback>
        </Form.Group>

        {/* Password */}
        <Form.Group controlId='password'>
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              name='password'
              placeholder='Password'
              value={authForm.password}
              onChange={handleChange}
              required
              pattern='^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,16}$'
            />
            <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
              {showPassword ? <EyeSlashFill color='#4a0f73' /> : <EyeFill color='#4a0f73' />}
            </InputGroup.Text>
            <Form.Control.Feedback type='invalid' style={{ background: 'red', color: 'white' }}>
              Password must be 6-16 chars, include 1 digit, 1 uppercase, and 1 special character.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {/* Confirm Password */}
        <Form.Group controlId='confirmPassword'>
          <InputGroup>
            <Form.Control
              type={showConfirmPassword ? 'text' : 'password'}
              name='confirmPassword'
              placeholder='Confirm Password'
              value={authForm.confirmPassword}
              onChange={handleChange}
              required
              isInvalid={validated && authForm.confirmPassword !== authForm.password}
            />
            <InputGroup.Text onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
              {showConfirmPassword ? <EyeSlashFill color='#4a0f73' /> : <EyeFill color='#4a0f73' />}
            </InputGroup.Text>
            <Form.Control.Feedback type='invalid' style={{ background: 'red', color: 'white' }}>
              Passwords do not match.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {/* Address (optional) */}
        <Form.Group controlId='address'>
          <Form.Control
            type='text'
            name='address'
            placeholder='Address (optional)'
            value={authForm.address}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Zip Code (optional) */}
        <Form.Group controlId='zipCode'>
          <Form.Control
            type='text'
            name='zipCode'
            placeholder='Canadian Zip Code'
            value={authForm.zipCode}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Role Dropdown */}
        <Form.Group controlId='role'>
          <Form.Label className='mt-3 text-light'>Select Your Role</Form.Label>
          <Form.Select
            name='role'
            value={authForm.role}
            onChange={handleChange}
            required
          >
            <option value='lookingForLawyer'>Looking for a lawyer</option>
            <option value='lawyer'>Lawyer</option>
            <option value='paralegal'>Paralegal</option>
            <option value='none'>None of the Above</option>
          </Form.Select>
          <Form.Control.Feedback type='invalid' style={{ background: 'red', color: 'white' }}>
            Please select a role.
          </Form.Control.Feedback>
        </Form.Group>

        {/* Lawyer Fields */}
        {authForm.role === 'lawyer' && (
          <>
            <Form.Group controlId='barNumber' className='mt-3'>
              <Form.Label className='text-light'>Bar Number / License Number</Form.Label>
              <Form.Control
                type='text'
                name='barNumber'
                value={authForm.barNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='jurisdiction' className='mt-3'>
              <Form.Label className='text-light'>Jurisdiction(s)</Form.Label>
              <Form.Control
                type='text'
                name='jurisdiction'
                placeholder='e.g., State or Province'
                value={authForm.jurisdiction}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='lawFirm' className='mt-3'>
              <Form.Label className='text-light'>Law Firm / Employer (optional)</Form.Label>
              <Form.Control
                type='text'
                name='lawFirm'
                value={authForm.lawFirm}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId='yearsOfPractice' className='mt-3'>
              <Form.Label className='text-light'>Years of Practice</Form.Label>
              <Form.Control
                type='number'
                name='yearsOfPractice'
                value={authForm.yearsOfPractice}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='practiceAreas' className='mt-3'>
              <Form.Label className='text-light'>Practice Areas (comma-separated)</Form.Label>
              <Form.Control
                type='text'
                name='practiceAreas'
                placeholder='e.g., Family, Criminal Defense, Personal Injury'
                value={authForm.practiceAreas}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId='supportingDocuments' className='mt-3'>
              <Form.Label className='text-light'>Upload Supporting Documents (optional)</Form.Label>
              <Form.Control
                type='file'
                name='supportingDocuments'
                onChange={handleChange}
              />
            </Form.Group>
          </>
        )}

        {/* Paralegal Fields */}
        {authForm.role === 'paralegal' && (
          <>
            <Form.Group controlId='paralegalCertificate' className='mt-3'>
              <Form.Label className='text-light'>Paralegal Certification/Diploma</Form.Label>
              <Form.Control
                type='text'
                placeholder='Certificate Number or School'
                name='paralegalCertificate'
                value={authForm.paralegalCertificate}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='paralegalCertFile' className='mt-3'>
              <Form.Label className='text-light'>Upload Certificate (optional)</Form.Label>
              <Form.Control
                type='file'
                name='paralegalCertFile'
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId='paralegalLawFirm' className='mt-3'>
              <Form.Label className='text-light'>Name of Supervising Attorney or Law Firm</Form.Label>
              <Form.Control
                type='text'
                name='paralegalLawFirm'
                value={authForm.paralegalLawFirm}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='paralegalAssociation' className='mt-3'>
              <Form.Label className='text-light'>Professional Association Membership</Form.Label>
              <Form.Control
                type='text'
                name='paralegalAssociation'
                placeholder='e.g., local or national paralegal association'
                value={authForm.paralegalAssociation}
                onChange={handleChange}
              />
            </Form.Group>
          </>
        )}

        {/* Law Student Fields */}
        {authForm.role === 'lawStudent' && (
          <>
            <Form.Group controlId='lawSchool' className='mt-3'>
              <Form.Label className='text-light'>Law School Name</Form.Label>
              <Form.Control
                type='text'
                name='lawSchool'
                value={authForm.lawSchool}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='graduationYear' className='mt-3'>
              <Form.Label className='text-light'>Expected Graduation Year</Form.Label>
              <Form.Control
                type='number'
                name='graduationYear'
                value={authForm.graduationYear}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='studentID' className='mt-3'>
              <Form.Label className='text-light'>Student ID</Form.Label>
              <Form.Control
                type='text'
                name='studentID'
                value={authForm.studentID}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='enrollmentProof' className='mt-3'>
              <Form.Label className='text-light'>Upload Proof of Enrollment (optional)</Form.Label>
              <Form.Control
                type='file'
                name='enrollmentProof'
                onChange={handleChange}
              />
            </Form.Group>
          </>
        )}

        {/* Looking for a Lawyer */}
        {authForm.role === 'lookingForLawyer'}

        {/* "None of the Above" => no extra fields */}
        {authForm.role === 'none' && (
          <p className='text-light mt-3'>
            You selected "None of the Above." No extra info required.
          </p>
        )}

        {/* Terms of Use Checkbox */}
        <Form.Group controlId='termsCheckbox' className='my-3'>
          <Form.Check
            type='checkbox'
            label={
              <span style={{ color: '#fff' }}>
                I have read and agree to the{' '}
                <a
                  href='#'
                  style={{ color: '#fff', textDecoration: 'underline' }}
                  onClick={handleOpenTerms}
                >
                  Olanur Legal Buddy Terms of Use
                </a>
              </span>
            }
            checked={termsChecked}
            onChange={(e) => setTermsChecked(e.target.checked)}
            required
            isInvalid={validated && !termsChecked}
            feedback='You must agree to the Terms of Use.'
            feedbackType='invalid'
            feedbackProps={{ style: { color: 'white' } }}
          />
        </Form.Group>

        {/* Submit Button */}
        <Button type='submit' disabled={isLoading}>
          {isLoading ? (
            <Spinner animation='border' size='sm' />
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              fill='currentColor'
              className='bi bi-person-fill-check'
              viewBox='0 0 20 20'
            >
              <path d='M12.5 16a3.5 3.5 0 1 0 0-7 
                       3.5 3.5 0 0 0 0 7
                       m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144
                       l-.774-.773a.5.5 0 0 1 .708-.708
                       l.547.548 1.17-1.951a.5.5 0 1 1 .858.514
                       M11 5a3 3 0 1 1-6 0 
                       3 3 0 0 1 6 0' />
              <path d='M2 13c0 1 1 1 1 1h5.256
                       A4.5 4.5 0 0 1 8 12.5
                       a4.5 4.5 0 0 1 1.544-3.393
                       Q8.844 9.002 8 9
                       c-5 0-6 3-6 4' />
            </svg>
          )}{' '}
          Sign Up
        </Button>

        {message && <div style={{ marginTop: '1rem', color: '#fff' }}>{message}</div>}
      </Form>

      {/* Snackbar for Success/Error Messages */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={10000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={message.includes('successful') ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Modal: Terms of Use */}
      <Modal
        show={showTermsModal}
        onHide={handleCloseTerms}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Olanur Legal Buddy Terms of Use</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {/* Insert your Terms of Use text or references here */}
          <h5>Welcome to Olanur Legal Buddy!</h5>
          <h1>Terms and Conditions</h1>
          <p><strong>Effective Date:</strong> [02-02-2025]</p>
         
  <h2>1. Acceptance of Terms</h2>

  <h3>1.1 Binding Agreement</h3>
  <p>
    By registering, accessing, or using <strong>Olanur Legal Buddy</strong> (hereinafter
    referred to as the “Platform”), you (“User,” “you,” or “your”) agree to be bound
    by these Terms and Conditions (the “Terms”). If you do not agree to all the Terms,
    you are not permitted to use the Platform.
  </p>

  <h3>1.2 Eligibility</h3>
  <p>
    You must be at least 18 years of age (or the age of majority in your jurisdiction)
    and have the legal capacity to enter into a binding contract to use the Platform.
    By using the Platform, you represent and warrant that you meet these eligibility
    requirements.
  </p>


  <h2>2. Description of Services</h2>

  <h3>2.1 AI-Driven Legal Services Hub</h3>
  <p>
    <strong>Olanur Legal Buddy</strong> provides access to AI-powered tools designed to
    streamline and simplify legal processes, including but not limited to:
  </p>
  <ul>
    <li>Document Management</li>
    <li>Document Summarizing</li>
    <li>Legal Assistant Chat</li>
    <li>Lawyer-Finding &amp; Matching Services</li>
  </ul>

  <h3>2.2 No Attorney-Client Relationship Through the Platform</h3>
  <p>
    The Platform is a technology tool. Any interaction through AI features, chatbots,
    or posted content does <strong>not</strong> create an attorney-client relationship
    between you and <strong>Olanur Legal Buddy</strong>. If you choose to hire a lawyer
    through the Platform, your relationship (including attorney-client privileges) is
    solely with that lawyer and governed by their engagement letter or separate
    contractual terms. <strong>Olanur Legal Buddy</strong> is not a law firm and does
    not provide legal advice.
  </p>

  <h3>2.3 Accuracy of Information</h3>
  <p>
    While we strive to ensure that the AI-driven services (e.g., legal assistant chat,
    document summarizing) provide helpful and up-to-date information, we cannot
    guarantee the accuracy, reliability, or completeness of any information or
    documents generated. You should consult a qualified attorney for legal advice
    specific to your situation.
  </p>


  <h2>3. User Accounts</h2>

  <h3>3.1 Account Creation</h3>
  <p>
    To access certain features, you may be required to create a user account.
    You agree to:
  </p>
  <ul>
    <li>Provide true, accurate, current, and complete information about yourself.</li>
    <li>Maintain and promptly update this information to keep it accurate and complete.</li>
  </ul>

  <h3>3.2 Account Security</h3>
  <p>
    You are responsible for maintaining the confidentiality of your login credentials
    and for all activities that occur under your account. You agree to immediately
    notify <strong>Olanur Legal Buddy</strong> of any unauthorized use of your
    account or any other breach of security.
  </p>

  <h3>3.3 Multiple Accounts Prohibited</h3>
  <p>
    You may not create multiple user accounts to circumvent restrictions or for any
    deceptive purpose. We reserve the right to terminate any accounts we believe to be
    duplicated or created for improper purposes.
  </p>


  <h2>4. Lawyer-Finding &amp; Matching Services</h2>

  <h3>4.1 Platform Role</h3>
  <p>
    <strong>Olanur Legal Buddy</strong> may help match you with licensed lawyers based
    on your stated preferences. <strong>Olanur Legal Buddy</strong> does <strong>not</strong>
    endorse or recommend any specific lawyer, nor does <strong>Olanur Legal Buddy</strong>
    guarantee the competence, quality, or reliability of any lawyer listed.
  </p>

  <h3>4.2 Independent Lawyers</h3>
  <p>
    Lawyers using the Platform are independent professionals. They are <strong>not</strong>
    employees or agents of <strong>Olanur Legal Buddy</strong>. It is your responsibility
    to review and negotiate the terms of any engagement with a lawyer and to ensure they
    have the necessary credentials and experience for your legal matter.
  </p>

  <h3>4.3 No Liability for Legal Services</h3>
  <p>
    You agree that <strong>Olanur Legal Buddy</strong> is <strong>not liable</strong> for
    any acts, omissions, or services provided by any lawyer with whom you may connect
    through the Platform.
  </p>


  <h2>5. Use of AI-Driven Features</h2>

  <h3>5.1 Nature of AI Services</h3>
  <p>
    Our AI tools (e.g., document management, document summarizing, legal chat assistant)
    are intended to assist you in your legal tasks but are <strong>not a substitute</strong>
    for professional legal advice.
  </p>

  <h3>5.2 User Responsibility</h3>
  <p>
    You acknowledge that you are responsible for verifying the accuracy of AI-generated
    responses, summaries, or suggestions before relying on them. You should consult a
    qualified attorney for verification and guidance.
  </p>

  <h3>5.3 Limitations</h3>
  <p>
    You agree that <strong>Olanur Legal Buddy</strong> does <strong>not</strong> guarantee
    the AI’s accuracy or usefulness, and that errors or omissions may occur.
    <strong>Olanur Legal Buddy</strong> disclaims all liability for any damage arising
    from reliance on these AI-driven features.
  </p>


  <h2>6. User Content</h2>

  <h3>6.1 Ownership</h3>
  <p>
    You retain ownership of any documents, text, images, or other materials (“User Content”)
    you upload to the Platform. However, by uploading User Content, you grant
    <strong>Olanur Legal Buddy</strong> a non-exclusive, worldwide, royalty-free license
    to use, reproduce, modify, and display the User Content for the purpose of operating
    and improving the Platform.
  </p>

  <h3>6.2 Prohibited Content</h3>
  <p>
    You agree not to upload any content that is unlawful, harmful, defamatory, obscene,
    harassing, or infringing upon the rights of others. We reserve the right to remove
    any content that violates these Terms or applicable laws.
  </p>

  <h3>6.3 Data Privacy</h3>
  <p>
    Our handling of personal data is governed by our <strong>Privacy Policy</strong>,
    which is incorporated by reference into these Terms. By using the Platform, you agree
    to the collection, storage, and use of data as described in the Privacy Policy.
  </p>


  <h2>7. Fees and Payments</h2>

  <h3>7.1 Platform Fees</h3>
  <p>
    Certain features or services may require payment of fees to
    <strong>Olanur Legal Buddy</strong>. All applicable fees will be disclosed prior to
    your incurring them. You agree to pay all fees associated with your use of the Platform.
  </p>

  <h3>7.2 Third-Party Fees</h3>
  <p>
    If you engage a lawyer through the Platform, you may be charged professional fees
    directly by the lawyer. <strong>Olanur Legal Buddy</strong> is not responsible for
    any dispute or issue related to these fees.
  </p>

  <h3>7.3 Payment Method</h3>
  <p>
    You must provide a valid payment method (e.g., credit card) if required for any
    fee-based services. You authorize <strong>Olanur Legal Buddy</strong> to charge your
    payment method for any fees due.
  </p>

  <h3>7.4 Refunds</h3>
  <p>
    Any request for refunds related to fees paid to <strong>Olanur Legal Buddy</strong>
    is subject to our <strong>Refund Policy</strong>, which will be separately provided
    or outlined on the Platform. Fees paid directly to lawyers are governed by their
    individual refund or billing policies.
  </p>


  <h2>8. Intellectual Property</h2>

  <h3>8.1 Platform Intellectual Property</h3>
  <p>
    All technology, software, trademarks, logos, text, and design elements on the Platform
    (excluding User Content) are owned by or licensed to <strong>Olanur Legal Buddy</strong>.
    You are granted a limited, non-transferable, non-exclusive license to access and use
    the Platform solely for lawful purposes. You may not reproduce, modify, create
    derivative works, or publicly display any content without our express written permission.
  </p>

  <h3>8.2 Feedback</h3>
  <p>
    If you provide feedback, suggestions, or ideas regarding the Platform, you grant
    <strong>Olanur Legal Buddy</strong> an unrestricted, worldwide, royalty-free license
    to use such feedback for any purpose without any obligation to you.
  </p>


  <h2>9. Disclaimers</h2>

  <h3>9.1 No Legal Advice</h3>
  <p>
    <strong>Olanur Legal Buddy</strong> and its AI-driven services do <strong>not</strong>
    constitute legal advice or legal services. Content generated by the Platform, whether
    by AI or human contributors, is provided for informational purposes only.
  </p>

  <h3>9.2 No Warranties</h3>
  <p>
    The Platform is provided on an “as is” and “as available” basis. To the fullest extent
    permitted by law, <strong>Olanur Legal Buddy</strong> disclaims all warranties, whether
    express or implied, including the warranties of merchantability, fitness for a particular
    purpose, and non-infringement.
  </p>

  <h3>9.3 Availability and Accuracy</h3>
  <p>
    <strong>Olanur Legal Buddy</strong> does not guarantee that the Platform will be
    error-free, uninterrupted, secure, or that any information provided is accurate,
    complete, or useful. Use the Platform at your own risk.
  </p>

 
  <h2>10. Limitation of Liability</h2>

  <h3>10.1 Exclusion of Certain Damages</h3>
  <p>
    To the maximum extent permitted by law, <strong>Olanur Legal Buddy</strong>, its
    directors, employees, partners, affiliates, and suppliers shall not be liable for
    any indirect, consequential, exemplary, incidental, special, or punitive damages,
    including loss of data, revenue, or profits arising from your use (or inability to
    use) the Platform.
  </p>

  <h3>10.2 Cap on Liability</h3>
  <p>
    In no event shall <strong>Olanur Legal Buddy</strong>’s total liability to you for
    all claims arising out of or in connection with these Terms exceed the amounts
    paid by you to <strong>Olanur Legal Buddy</strong> for use of the Platform in the
    twelve (12) months preceding the event giving rise to the liability.
  </p>


  <h2>11. Indemnification</h2>
  <p>
    You agree to indemnify, defend, and hold harmless <strong>Olanur Legal Buddy</strong>,
    its affiliates, and their respective directors, officers, employees, contractors,
    and agents from and against any third-party claims, losses, damages, liabilities,
    costs, and expenses (including reasonable attorneys’ fees) arising out of or relating
    to:
  </p>
  <ul>
    <li>Your use or misuse of the Platform.</li>
    <li>Your violation of these Terms.</li>
    <li>Your violation of any law or the rights of a third party (including intellectual
      property rights).</li>
    <li>Any disputes you may have with lawyers or other users.</li>
  </ul>


  <h2>12. Termination</h2>

  <h3>12.1 Termination by User</h3>
  <p>You may delete your account and cease using the Platform at any time.</p>

  <h3>12.2 Termination by Olanur Legal Buddy</h3>
  <p>
    We reserve the right to suspend or terminate your account or access to the Platform,
    at any time and without notice, for any violation of these Terms or for any other
    reason we deem appropriate.
  </p>

  <h3>12.3 Effect of Termination</h3>
  <p>
    Upon termination, all licenses and rights granted to you in these Terms will
    immediately cease. However, any accrued rights and obligations (including payment
    obligations) and all provisions related to liability, indemnification, and dispute
    resolution shall survive termination.
  </p>


  <h2>13. Governing Law and Dispute Resolution</h2>

  <h3>13.1 Governing Law</h3>
  <p>
    These Terms are governed by and construed in accordance with the laws of
    Canada, without regard to conflict of laws principles.
  </p>

  <h3>13.2 Arbitration</h3>
  <p>
    Any dispute or claim arising out of or relating to these Terms or your use of the
    Platform shall be settled by binding arbitration administered by Canadian Courts
    in accordance with its rules. Judgment on the award rendered by the arbitrator(s)
    may be entered in any court having jurisdiction thereof.
  </p>

  <h3>13.3 Injunctive Relief</h3>
  <p>
    Nothing in this Section shall prevent either party from seeking injunctive or other
    equitable relief in a court of competent jurisdiction to prevent or restrain the
    breach of any obligations hereunder.
  </p>

 
  <h2>14. Modifications to Terms</h2>

  <h3>14.1 Right to Modify</h3>
  <p>
    We may revise or update these Terms from time to time in our sole discretion. If we
    make material changes, we will provide notice, such as posting a notice on the Platform
    or sending you an email.
  </p>

  <h3>14.2 Acceptance of Modified Terms</h3>
  <p>
    Your continued use of the Platform after any such modification constitutes your
    acceptance of the updated Terms. If you do not agree to the changes, you should stop
    using the Platform immediately.
  </p>

   <h2>15. Miscellaneous</h2>

  <h3>15.1 Entire Agreement</h3>
  <p>
    These Terms, together with the Privacy Policy and any other legal notices published
    by <strong>Olanur Legal Buddy</strong>, constitute the entire agreement between you
    and <strong>Olanur Legal Buddy</strong> regarding the Platform.
  </p>

  <h3>15.2 Severability</h3>
  <p>
    If any provision of these Terms is found to be unlawful, void, or unenforceable,
    that provision will be deemed severable from these Terms and will not affect the
    validity and enforceability of the remaining provisions.
  </p>

  <h3>15.3 No Waiver</h3>
  <p>
    No waiver by <strong>Olanur Legal Buddy</strong> of any breach of these Terms shall
    be a waiver of any subsequent breach. Failure to enforce any provision of these
    Terms does not constitute a waiver.
  </p>

  <h3>15.4 Assignment</h3>
  <p>
    You may not assign or transfer any rights or obligations under these Terms without
    our prior written consent. We may freely assign or transfer these Terms in the
    event of a merger, acquisition, or sale of assets.
  </p>

  <h3>15.5 Contact Information</h3>
  <p>
    If you have any questions about these Terms, please contact us at:
    <strong>Admin@olanurlegalbuddy</strong>
  </p>
          {/* …the rest of your Terms content… */}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='primary' onClick={handleCloseTerms}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Signup;
