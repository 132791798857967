import axiosInstance from '../../api/axiosInstance';
import { jwtDecode } from 'jwt-decode';

const USERMANAGEMENT_API = '/UserManagement';

const AuthService = {

  register: async (username, email, password, firstName, lastName) => {
    try {
      const response = await axiosInstance.post('/registration', {
        userId: 0,
        username,
        email,
        passwordHash: password,
        createdAt: new Date(),
        updatedAt: new Date(),
        resetToken: '',
        tokenExpiry: new Date(),
        firstName,
        lastName
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  login: async (username, password) => {
    try {
      const response = await axiosInstance.post('/login', null, {
        params: { username, password }
      });

      if (response.data) {
        const token = response.data;
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

        // Store token and expiration time in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('tokenExpiry', expirationTime);

        // Set automatic logout after the token expires (2 hours)
        const timeUntilExpiry = expirationTime - Date.now();
        setTimeout(() => {
          AuthService.Logout();
        }, timeUntilExpiry);

        return response.data;
      }
    } catch (error) {
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  userProfile: async () => {
    try {
      const response = await axiosInstance.get('/userprofile');
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  Logout: async (e) => {
    const response = await axiosInstance.post('/logout');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
    window.location.href = '/'; // Redirect to login page
  },

  requestPasswordReset: async (email) => {
    try {
      const response = await axiosInstance.post('/passwordresetrequest', null, {
        params: { email }
      });

      if (response.status !== 200) {
        throw new Error(response.data);
      }
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  resetPassword: async (token, newPassword) => {
    try {
      const response = await axiosInstance.post('/passwordreset', { token, newPassword });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  updateUser: async (userData) => {
    try {
      const response = await axiosInstance.put(USERMANAGEMENT_API + '/user', userData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  updatePassword: async (oldPassword, newPassword) => {
    try {
      const response = await axiosInstance.put(USERMANAGEMENT_API + '/user/update-password', { oldPassword, newPassword });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error('Network Error');
    }
  },

  getCurrentUser: async () => {
    const token = localStorage.getItem('token');
    const tokenExpiry = localStorage.getItem('tokenExpiry');

    if (token && tokenExpiry) {
      // Check if the token is expired
      const currentTime = Date.now();
      if (currentTime > tokenExpiry) {
        AuthService.Logout(); // Log out the user if the token is expired
        return null;
      }

      const decoded = jwtDecode(token);
      return decoded;
    }
    return null;
  },

  confirmUserRegistration: async (token) => {
    try {
        const response = await axiosInstance.get(USERMANAGEMENT_API + '/UserManagement/confirm', {
            params: { token },
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Network Error');
    }
  }
};

export default AuthService;
