// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-details{
    background: #FFF;
    border-radius: 8px;
    min-height: 80vh;
}

.file-details h5 {
    text-align: center;
    color: black;
}

.file-details-content {
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

.recent-activities, .quick-actions {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px;
    width: 45%;
}

.recent-activities h2, .quick-actions h2 {
    color: #FFF;
}

.recent-activities ul {
    list-style-type: none;
    padding: 0;
}

.recent-activities li {
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
}

button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #351054; /* Darker purple for buttons */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #292343;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Folders/FolderDetails.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,aAAa;IACb,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,iDAAiD;IACjD,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,yBAAyB,EAAE,8BAA8B;IACzD,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".file-details{\n    background: #FFF;\n    border-radius: 8px;\n    min-height: 80vh;\n}\n\n.file-details h5 {\n    text-align: center;\n    color: black;\n}\n\n.file-details-content {\n    display: flex;\n    justify-content: space-around;\n    padding: 10px;\n}\n\n.recent-activities, .quick-actions {\n    background: rgba(255, 255, 255, 0.1);\n    border-radius: 8px;\n    padding: 15px;\n    width: 45%;\n}\n\n.recent-activities h2, .quick-actions h2 {\n    color: #FFF;\n}\n\n.recent-activities ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.recent-activities li {\n    padding: 8px 0;\n    border-bottom: 1px solid rgba(255, 255, 255, 0.5);\n    margin-bottom: 5px;\n}\n\nbutton {\n    width: 100%;\n    padding: 10px;\n    margin-top: 10px;\n    background-color: #351054; /* Darker purple for buttons */\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\nbutton:hover {\n    background-color: #292343;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
